import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import TrekkingCard from './TrekkingCard'; // Make sure TrekkingCard is imported

const TrekkingSlider = () => {
    const [trekData, setTrekData] = useState([]); // State to hold fetched data
    const [loading, setLoading] = useState(true); // State to track loading state
    const [error, setError] = useState(null); // State to track errors

    useEffect(() => {
        // Fetch data from the backend
        fetch('https://explorespiti-backend.vercel.app/api/treks')  // Replace with actual API endpoint
            .then((response) => response.json())
            .then((data) => {
                setTrekData(data); // Store fetched data
                setLoading(false); // Update loading state
            })
            .catch((err) => {
                setError('Failed to fetch data');
                setLoading(false); // Stop loading on error
            });
    }, []); // Empty dependency array means this runs once when the component mounts

    if (loading) {
        return <div>Loading...</div>; // Show loading indicator while fetching
    }

    if (error) {
        return <div>{error}</div>; // Show error if fetch fails
    }

    return (
        <div className="w-full max-w-7xl mx-auto p-4 flex flex-col items-center justify-center">
            <h1 className="text-3xl font-bold text-primary headerText text-center my-8">Trek De Spiti</h1>

            <Swiper
                grabCursor={true}
                spaceBetween={80}
                slidesPerView={'auto'}
                centeredSlides={false}
                pagination={{ clickable: true }}
                modules={[Pagination]}
                className="w-full"
                breakpoints={{
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 10,
                    },
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                    },
                    1280: {
                        slidesPerView: 4,
                        spaceBetween: 40,
                    },
                }}
            >
                {trekData.map((trek) => (
                    <SwiperSlide key={trek._id} className="py-8">
                        <TrekkingCard trek={trek} /> {/* Assuming TrekkingCard takes a single trek object */}
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default TrekkingSlider;
