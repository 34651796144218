// Popular Destinations images

import DHANKAR from "../../assets/places/dhankar1.webp";
import KEYM from "../../assets/places/key1.jpeg";
import LANGZA from "../../assets/places/langza.jpg";
import CHANDRATAAL from "../../assets/places/chandrataal.jpg";
import SRIVER from "../../assets/places/f3.jpg";
import TABO from "../../assets/places/tabopaint.webp";
import NAKO from "../../assets/places/nakolake.webp";

export const PopularData = [
    {
        id: 1,
        name: "Key Monastery",
        description: "A thousand-year-old Tibetan Buddhist monastery perched atop a hill at an altitude of 4,166 meters (13,668 ft).",
        image: KEYM, // Local image
        activities: ["Explore ancient murals", "Attend morning prayers", "Enjoy panoramic views of Spiti Valley"],
        bestTimeToVisit: "May to October",
    },
    {
        id: 2,
        name: "Dhankar Monastery",
        description: "A 1200-year-old monastery precariously perched between Spiti and Pin Valleys, offering breathtaking views.",
        image: DHANKAR, // Local image
        activities: ["Explore the monastery", "Hike to Dhankar Lake", "Photograph the stunning landscape"],
        bestTimeToVisit: "June to September",
    },
    {
        id: 3,
        name: "Langza Buddha Statue",
        description: "A 1,000-year-old Buddha statue overlooking the village of Langza at an altitude of 4,400 meters (14,435 ft).",
        image: LANGZA, // Local image
        activities: ["Meditate at the statue", "Fossil hunting", "Experience local Spitian life"],
        bestTimeToVisit: "May to October",
    },
    {
        id: 4,
        name: "Chandrataal Lake",
        description: "A stunning crescent-shaped lake located at an altitude of 4,300 meters (14,107 ft) surrounded by snow-capped peaks.",
        image: CHANDRATAAL, // Local image
        activities: ["Camping by the lake", "Stargazing at night", "Hiking to the lake"],
        bestTimeToVisit: "June to September",
    },
    {
        id: 5,
        name: "Spiti River Valley",
        description: "The lifeline of Spiti Valley, meandering through rugged landscapes and picturesque villages.",
        image: SRIVER, // Local image
        activities: ["River rafting", "Photography along the banks", "Relaxing by the serene river"],
        bestTimeToVisit: "June to October",
    },
    {
        id: 6,
        name: "Tabo Monastery",
        description: "A UNESCO World Heritage Site, this ancient monastery is home to centuries-old frescoes and thangkas.",
        image: TABO, // Local image
        activities: ["Explore the ancient monastery", "Learn about its rich history", "Visit the caves nearby"],
        bestTimeToVisit: "May to October",
    },
    {
        id: 7,
        name: "Nako Lake",
        description: "A high-altitude lake surrounded by willow and poplar trees, offering tranquility and scenic beauty.",
        image: NAKO, // Local image
        activities: ["Boating on the lake", "Visit Nako Monastery", "Hike around the lake"],
        bestTimeToVisit: "May to October",
    },
];
