import React from 'react';
import { Link } from 'react-router-dom';

const TourCard = ({ id, imgSrc, altText, title, highlights, date, seats, price }) => {
    return (
        <Link to={`/tour/${id}`} className="block">
            <div className="sm:w-60 sm:h-56 w-36 h-44 rounded-2xl bg-slate-100 p-2 overflow-hidden shadow-lg transition-transform duration-500 ease-out hover:scale-105 flex flex-col">
                <div className="sm:h-32 h-24 rounded-lg bg-gradient-to-br from-[#04a0bb] to-[#50f6ff] relative flex flex-col">
                    <img
                        src={imgSrc}
                        alt={altText}
                        className="w-full h-full object-cover rounded-lg"
                    />
                </div>
                <div className="mt-1 px-1 flex-0">
                    <span className="block text-center text-primary sm:text-sm text-xs font-medium">
                        {title}
                    </span>
                </div>
                <div className="flex justify-between sm:mt-4 mt-2">
                    <div className="flex-1 text-center text-primary px-1 text-nowrap">
                        <span className="block sm:text-sm text-[12px]">{date}</span>
                        <span className="block text-xs text-black">Date</span>
                    </div>
                    <div className="flex-1 text-center text-primary px-1 border-l border-r border-[#00000020]">
                        <span className="block sm:text-sm text-[12px]">{seats}</span>
                        <span className="block text-xs text-black">Seats</span>
                    </div>
                    <div className="flex-1 text-center text-primary px-1">
                        <span className="block sm:text-sm text-[12px]">{price}</span>
                        <span className="block text-xs text-black">Price</span>
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default TourCard;