import React, { useState } from 'react';
import { MdClose } from 'react-icons/md';
import axios from 'axios';

const ContactModal = ({ isOpen, onClose }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        contactNo: '',
        message: '',
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post('https://explorespiti-backend.vercel.app/api/contact/add', formData);
            alert('Contact form submitted successfully');
            onClose(); // Close modal after submission
        } catch (error) {
            alert('Failed to submit contact form');
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-gray-800 w-[34rem] rounded-lg shadow-lg relative">

                {/* Screen Header */}
                <div className="flex justify-between items-center bg-gray-700/50 px-4 py-2 rounded-t-md">
                    <div className="flex space-x-2">
                        <div className="w-2 h-2 bg-red-600 rounded-full"></div>
                        <div className="w-2 h-2 bg-yellow-400 rounded-full"></div>
                        <div className="w-2 h-2 bg-green-500 rounded-full"></div>
                    </div>
                    {/* Close Button */}
                    <button onClick={onClose} className="flex top-3 right-3 text-white text-sm">
                        <MdClose />
                    </button>
                </div>

                {/* Screen Body */}
                <div className="flex flex-col md:flex-row justify-between p-6">
                    <div className="flex flex-col space-y-2">
                        <div className="text-orange-500 text-xl font-bold tourHeroText">
                            <span>Contact Us</span>
                        </div>
                        <div className="text-gray-400 text-xs">Contact Info: +91 764 992 0808</div>
                    </div>

                    <form className="flex-1 ml-6 space-y-2" onSubmit={handleSubmit}>
                        <div>
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                className="w-full bg-transparent border-b border-gray-500 p-1 text-xs text-white placeholder-gray-500 focus:outline-none focus:border-orange-500"
                                placeholder="Name"
                                required
                            />
                        </div>
                        <div>
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="w-full bg-transparent border-b border-gray-500 p-1 text-xs text-white placeholder-gray-500 focus:outline-none focus:border-orange-500"
                                placeholder="Email"
                                required
                            />
                        </div>
                        <div>
                            <input
                                type="text"
                                name="contactNo"
                                value={formData.contactNo}
                                onChange={handleChange}
                                className="w-full bg-transparent border-b border-gray-500 p-1 text-xs text-white placeholder-gray-500 focus:outline-none focus:border-orange-500"
                                placeholder="Contact No"
                                required
                            />
                        </div>
                        <div>
                            <input
                                type="text"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                className="w-full bg-transparent border-b border-gray-500 py-4 px-1 text-xs text-white placeholder-gray-500 focus:outline-none focus:border-orange-500"
                                placeholder="Message"
                                required
                            />
                        </div>

                        {/* Action buttons */}
                        <div className="flex justify-end space-x-4">
                            <button
                                type="button"
                                className="text-orange-500 hover:text-orange-700 text-xs font-semibold transition duration-300"
                                onClick={onClose}
                            >
                                CANCEL
                            </button>
                            <button
                                type="submit"
                                className="text-orange-500 hover:text-orange-700 text-xs font-semibold transition duration-300"
                            >
                                SEND
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ContactModal;
