import React from 'react'

import LEOPARD from "../../assets/bg/leopard.png"

export default function LeopardCTA() {
    return (
        <div class=" sm:pt-[100px] sm:pb-0 py-1">
            <section
                class="  relative py-10 bg-[url('https://images.pexels.com/photos/933054/pexels-photo-933054.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')] h-[300px] bg-no-repeat bg-cover bg-center"
            >
                <div class="absolute w-full bottom-0 mx-auto px-4">
                    <div class="  container mx-auto ">
                        <div class=" grid lg:grid-cols-3  lge:grid-cols-3 md:grid-cols-1 mdsm:grid-cols-1 sm:grid-cols-1 ">

                            <div class=" col-span-2 relative ">
                                <div class=" absolute bottom-0 py-14">
                                    <h3 class=" relative headerText text-gray-300 after:bg-[#fff] after:w-[10%] sm:text-4xl text-xl font-semibold pb-4">
                                        Snow Leopard Expedition
                                    </h3>
                                    <h4 class=" text-white font-medium  sm:text-xl text-sm ">
                                        We are the best in town when it comes to Snow Leopard sightings. Drop us your number and one of our team member will be reaching out to you.

                                    </h4>
                                    <form class="mt-5 flex max-w-md gap-x-4">

                                        <label for="phone" class="sr-only">Contact Number</label>
                                        <input id="phone" name="phone" type="text" required="" class="min-w-0 flex-auto rounded-md border-0 bg-black/50 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-orange-300 focus:ring-2 focus:ring-inset focus:ring-orange-500 sm:text-sm text-xs sm:leading-6" placeholder="Enter your contact" />

                                            <button type="submit" class="flex-none rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-primary shadow-sm hover:bg-orange-400 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">Submit</button>
                                    </form>
                                </div>
                            </div>
                            <div>
                                <div class="lg:block lge:block md:hidden mdsm:hidden sm:hidden hidden opacity-90">
                                    <img
                                        src={LEOPARD}
                                        alt="snow Leopard "
                                        class="  h-[500px] mx-auto "
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    )
}
