import React from "react";
import { FaCalendar, FaMapPin } from "react-icons/fa";

export default function DestinationLanding({ destination }) {
    return (
        <div className="min-h-screen bg-gray-100">
            {/* Hero Section */}
            <header
                className="relative h-[70vh] bg-cover bg-center"
                style={{ backgroundImage: `url(${destination.image})` }}
            >
                <div className="absolute inset-0 bg-black/50 flex flex-col items-center justify-center text-white text-center px-4">
                    <h1 className="text-3xl md:text-6xl font-bold mb-4 headerText text-primary">
                        {destination.name}
                    </h1>
                    <p className="text-xl md:text-xl max-w-2xl">{destination.description}</p>
                </div>
            </header>

            {/* Main Content */}
            <main className="max-w-5xl mx-auto px-6 py-12">
                <section className="mb-12">
                    <h2 className="text-3xl font-bold text-primary headerText mb-4">Overview</h2>
                    <p className="text-gray-600 text-lg leading-relaxed">
                        {destination.description}
                    </p>
                </section>

                <section className="mb-12">
                    <h2 className="text-3xl font-bold text-primary headerText mb-4">Activities</h2>
                    <ul className="list-disc list-inside text-gray-600 text-lg space-y-2">
                        {destination.activities.map((activity, index) => (
                            <li key={index}>{activity}</li>
                        ))}
                    </ul>
                </section>

                <section className="flex flex-wrap items-center justify-between gap-4 bg-gray-50 p-6 rounded-lg shadow-md">
                    <div className="flex items-center text-gray-600">
                        <FaCalendar className="w-6 h-6 mr-2 text-primary" />
                        <span className="text-lg">
                            Best Time to Visit: <strong>{destination.bestTimeToVisit}</strong>
                        </span>
                    </div>
                    <div className="flex items-center text-gray-600">
                        <FaMapPin className="w-6 h-6 mr-2 text-primary" />
                        <span className="text-lg">Location: Spiti Valley</span>
                    </div>
                </section>
            </main>

            {/* Call to Action */}
            <footer className="bg-primary text-white py-16 text-center">
                <div className="max-w-5xl mx-auto px-4">
                    <h2 className="text-3xl font-bold mb-4">
                        Plan Your Trip to {destination.name}
                    </h2>
                    <p className="mb-8 text-lg">
                        Book now to explore the breathtaking beauty of this destination.
                    </p>
                    <a
                        href="/book"
                        className="inline-block bg-white text-primary font-bold px-8 py-3 rounded-lg hover:bg-gray-100 transition-colors"
                    >
                        Book Now
                    </a>
                </div>
            </footer>
        </div>
    );
}
