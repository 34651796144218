import React from 'react';
import { Link } from 'react-router-dom';
import { FaCalendar, FaUsers, FaRupeeSign } from 'react-icons/fa';

const TestCard = ({ tour }) => {
    return (
        <Link to={`/tour/${tour.id}`} className="block">
            <div className="sm:w-60 sm:h-80 w-full rounded-2xl bg-white p-2 overflow-hidden shadow-lg transition-transform duration-500 ease-out hover:scale-105 flex flex-col">
                <div className="sm:h-40 h-32 rounded-lg relative">
                    <img
                        src={tour.images[0]}
                        alt={tour.title}
                        className="w-full h-full object-cover rounded-lg"
                    />
                    <div className="absolute bottom-2 right-2 bg-primary text-white px-2 py-1 rounded-full text-xs">
                        {tour.duration}
                    </div>
                </div>
                <div className="mt-2 px-1 flex-grow">
                    <h3 className="text-center text-primary sm:text-base text-sm font-semibold line-clamp-2">
                        {tour.title}
                    </h3>
                    <p className="text-gray-600 text-xs mt-1 line-clamp-2">{tour.description}</p>
                </div>
                <div className="flex justify-between mt-1 text-xs text-gray-600">
                    {/* <div className="flex items-center">
                        <FaCalendar className="mr-1" />
                        <span>{tour.startDate}</span>
                    </div> */}
                    <div className="flex items-center font-semibold">
                        <FaUsers className="mr-1 text-primary " />
                        <span>{tour.groupSize} people</span>
                    </div>
                    <div className="flex items-center text-primary font-semibold">
                        <FaRupeeSign className="mr-1" />
                        <span className="text-gray-600">{tour.price.discounted.toLocaleString()}</span>
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default TestCard;