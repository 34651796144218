import React from 'react';
import { FaFunnelDollar, FaLayerGroup, FaMountain } from 'react-icons/fa';

import BG from "../../assets/bg/10.png"

// Data array for Spiti Tour features
const spitiTourFeatures = [
    {
        icon: <FaFunnelDollar className="text-green-500 w-9 h-9" />,
        title: 'Optimize Your Travel Plan',
        description: 'Our customizable Spiti Valley tour plans ensure you make the most out of your trip with optimized travel routes and itineraries.',
    },
    {
        icon: <FaLayerGroup className="text-blue-600 w-9 h-9" />,
        title: 'Easy to Customize',
        description: 'Our Spiti Valley tour packages are flexible and easy to tailor based on your preferences, ensuring a personalized experience.',
    },
    {
        icon: <FaMountain className="text-red-500 w-9 h-9" />,
        title: 'Breathtaking Landscapes',
        description: 'Explore the stunning mountains, valleys, and rivers of Spiti with carefully curated routes that offer the best views.',
    },
];

const SpitiTourComponent = () => {
    return (
        <section className="py-10 bg-orange-50/30 text-black sm:py-8 lg:py-10">
            {/* <img
                src={BG}
                alt="trekker"
                className="absolute top-[850px] right-0 -z-[20] sm:opacity-20 opacity-20 w-[50%] h-auto object-contain -translate-x-1/5 -translate-y-1/4"
            /> */}
            <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                <div className="max-w-xl mx-auto text-center">
                    <p className="text-sm tracking-widest text-black font-bold uppercase">
                        Explore Spiti Valley
                    </p>

                    <h2 className="mt-2 sm:text-2xl text-primary text-xl font-bold text-nowrap headerText">
                        Customize Your Dream <span className='sm:block hidden'> Spiti Tour</span>
                    </h2>
                </div>

                <div className="grid items-center grid-cols-1 mt-8 gap-y-10 lg:grid-cols-5 sm:mt-20 gap-x-4">
                    <div className="space-y-8 lg:pr-16 xl:pr-24 lg:col-span-2 lg:space-y-12">
                        {spitiTourFeatures.map((feature, index) => (
                            <div key={index} className="flex items-start">
                                {feature.icon}
                                <div className="ml-5">
                                    <h3 className="sm:text-xl text-sm font-semibold text-secondary">
                                        {feature.title}
                                    </h3>
                                    <p className="mt-3 sm:text-base text-xs text-gray-600">
                                        {feature.description}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="lg:col-span-3">
                        <img
                            className="w-full rounded-lg shadow-xl"
                            src="https://images.pexels.com/photos/20883703/pexels-photo-20883703/free-photo-of-aerial-view-of-the-buddha-statue-in-the-langza-village-spiti-valley-india.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                            alt="Spiti Valley tour dashboard"
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SpitiTourComponent;
