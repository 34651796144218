import React from 'react';
import { Link } from 'react-router-dom';
import { MdSearch, MdTerrain, MdLocationOn } from 'react-icons/md';
import { trekData } from '../home/trekData';

const TrekLandingPage = () => {
    return (
        <div className="min-h-screen ">
            {/* Hero Section */}
            <div className="relative h-[80vh] bg-cover bg-center" style={{ backgroundImage: 'url(https://images.unsplash.com/photo-1464822759023-fed622ff2c3b?auto=format&fit=crop&q=80&w=2000)' }}>
                <div className="absolute inset-0 bg-black/50 flex flex-col items-center justify-center text-white">
                    <h1 className="text-5xl md:text-7xl font-bold mb-4 text-center text-primary headerText">Trek de Spiti</h1>
                    <p className="text-sm md:text-xl mb-8 text-center max-w-2xl">Embark on unforgettable trekking adventures in the heart of the Himalayas</p>
                    <div className="flex items-center bg-white rounded-full p-2 w-full max-w-md">
                        <MdSearch className="text-gray-600 text-2xl ml-2" />
                        <input type="text" placeholder="Search for treks..." className="w-full px-4 py-2 rounded-full focus:outline-none text-gray-800" />
                    </div>
                </div>
            </div>

            {/* Trek Grid */}
            <div className="max-w-7xl mx-auto px-4 py-16">
                <h2 className="text-3xl font-bold mb-8 text-center text-primary headerText">Our Treks</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {trekData.map((trek, index) => (
                        <TrekCard key={index} trek={trek} />
                    ))}
                </div>
            </div>

            {/* Features Section */}
            <div className="text-yellow-500 bg-gray-100 py-16">
                <div className="max-w-7xl mx-auto px-4">
                    <h2 className="text-3xl font-bold mb-12 text-center text-primary headerText">Why Trek with Us</h2>
                    <div className="grid md:grid-cols-3 gap-8">
                        <FeatureCard
                            icon={<MdTerrain className="text-4xl" />}
                            title="Expert Guides"
                            description="Our experienced guides ensure your safety and enrich your journey with local knowledge."
                        />
                        <FeatureCard
                            icon={<MdLocationOn className="text-4xl" />}
                            title="Unique Routes"
                            description="Discover hidden gems and off-the-beaten-path locations in the Spiti Valley."
                        />
                        <FeatureCard
                            icon={<MdSearch className="text-4xl" />}
                            title="Tailored Experiences"
                            description="From beginners to seasoned trekkers, we have adventures suited for all levels."
                        />
                    </div>
                </div>
            </div>

            {/* Call to Action */}
            <div className=" text-primary py-16">
                <div className="max-w-7xl mx-auto px-4 text-center">
                    <h2 className="text-3xl font-bold mb-4 headerText">Ready to Start Your Adventure?</h2>
                    <p className="mb-8 text-lg text-gray-500">Book your trek today and experience the magic of Spiti Valley</p>
                    <Link to="/contact" className="inline-block bg-yellow-400 text-yellow-800 font-bold px-8 py-3 rounded-lg hover:bg-gray-100 transition-colors">
                        Contact Us
                    </Link>
                </div>
            </div>
        </div>
    );
};

const TrekCard = ({ trek }) => (
    <Link to={`/trek/${trek.name}`} className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-xl transition-shadow">
        <img src={trek.images[0]} alt={trek.name} className="w-full h-48 object-cover" />
        <div className="p-6">
            <h3 className="text-xl font-bold mb-2 text-gray-800">{trek.name}</h3>
            <p className="text-gray-600 mb-4 line-clamp-2">{trek.overview}</p>
            <div className="flex justify-between text-sm text-gray-500">
                <span>{trek.trekStats.duration}</span>
                <span>{trek.trekStats.difficulty}</span>
            </div>
        </div>
    </Link>
);

const FeatureCard = ({ icon, title, description }) => (
    <div className="text-center">
        <div className="mb-4 inline-block p-4 bg-yellow-200 rounded-full">{icon}</div>
        <h3 className="text-xl font-bold mb-2">{title}</h3>
        <p className="text-gray-500">{description}</p>
    </div>
);

export default TrekLandingPage;