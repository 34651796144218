import React from 'react';
import '../../styles/whyus.scss';

export default function WhyUs() {
    const reasons = [
        {
            title: 'Expert local guides',
            description: 'Our team consists of experienced local guides who know the region inside out. Their knowledge of hidden gems, culture, and history ensures that you experience the true essence of Spiti Valley.',
            alignRight: true,
        },
        {
            title: 'Customizable Itineraries',
            description: 'We understand that every traveler is unique. Whether you’re an adventure enthusiast, a culture seeker, or simply looking to unwind, we offer customizable itineraries tailored to your preferences and interests.',
            alignRight: false,
        },
        {
            title: 'Sustainable Tourism',
            description: 'We are committed to promoting responsible tourism. By choosing us, you contribute to preserving Spiti Valley’s pristine environment and supporting the local communities through eco-friendly practices.',
            alignRight: true,
        },
        {
            title: 'Comprehensive Services',
            description: 'From comfortable stays in homestays and campsites to guided treks and tours, we offer a full range of services to ensure a hassle-free and enriching experience in Spiti Valley.',
            alignRight: false,
        },
    ];

    return (
        <div>
            <section className="sm:min-h-screen bg-white text-center sm:py-4 py-2 px-[2vw] sm:px-[10vw] xl:px-0 flex flex-col justify-center">
                <h1 className="sm:text-2xl text-primary text-xl font-bold text-center my-4 headerText">Why Choose Us</h1>
                <h1 className="text-orange-500 text-base md:text-xl font-normal max-w-3xl mx-auto sm:mb-10 mb-2 leading-snug">
                    Exploring Spiti Valley is an unforgettable adventure, and we make it even better.
                </h1>
                <div className="text-left grid sm:grid-cols-2 md:grid-cols-2 sm:gap-5 gap-2 sm:max-w-5xl grid-cols-2 mx-auto">
                    {reasons.map((reason, index) => (
                        <div key={index} className="card bg-orange-600 sm:p-10 p-4 relative">
                            <div className="circle"></div>
                            <div className={`relative ${reason.alignRight ? 'lg:pr-52' : 'lg:pl-48'}`}>
                                <h2 className="capitalize text-yellow-400 mb-4 text-xs sm:text-xl">{reason.title}</h2>
                                <p className="text-white sm:text-sm text-xs">{reason.description}</p>
                            </div>
                            <div className="icon"></div>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    );
}
