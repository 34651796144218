import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './app/Home';
import Tours from './app/Tours';
import Treks from './app/Treks';
import Blogs from './app/Blogs';
import ContactUs from './app/ContactUs';

import { Route, Routes, useParams } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import FloatingButton from './components/FloatingButton';
import TrekDetails from './components/treks/TrekDetails';

import { trekData } from './components/home/trekData';
import TourDetails from './components/tours/TourDetails';
import TrekList from './components/treks/TrekLanding';
import TrekLandingPage from './components/treks/TrekLanding';
import TestComponent from './components/testtour/TestComponent';
import TestDetails from './components/testtour/testDetails';
import PopularLanding from './components/popular/PopularLanding';
import DestinationLanding from './components/popular/DestinationLanding';
import { PopularData } from './components/popular/PopularData';


function App() {
  return (
    <div>
      {/* BrowserRouter removed from here */}
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/tours" element={<Tours />} /> */}
        <Route path="/treks" element={<TrekLandingPage />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/contact-us" element={<ContactUs />} />

        <Route path="/tour/:id" element={<TourDetails />} />
        {/* Define dynamic route for treks */}
        {/* <Route path="/trek/:id" element={<TrekDetails />} /> */}
        <Route path="/" element={<TrekList />} />
        <Route path="/trek/:id" element={<TrekDetails />} />

        <Route path='/tours' element={<TestComponent />} />
        <Route path="/tour/:id" element={<TestDetails />} />

        <Route path='/destination' element={<PopularLanding />} />
        <Route
          path="/destination/:id"
          element={<DestinationLandingWrapper />}
        />


      </Routes>
      <FloatingButton />
      <Footer />
    </div>
  );
}

function DestinationLandingWrapper() {
  // Get the destination ID from the URL
  const { id } = useParams();

  // Find the destination based on the ID
  const destination = PopularData.find((dest) => dest.id === parseInt(id));

  if (!destination) {
    return <div>Destination not found</div>;
  }

  return <DestinationLanding destination={destination} />;
}


export default App;
