import React from 'react';
import { Link } from 'react-router-dom';
import { PopularData } from './PopularData';
import { FiArrowRight, FiCalendar, FiMapPin } from 'react-icons/fi';

export default function PopularLanding() {
    return (
        <div className="min-h-screen bg-gray-100">
            {/* Hero Section */}
            <header
                className="relative h-[60vh] bg-cover bg-center"
                style={{
                    backgroundImage:
                        'url(https://images.unsplash.com/photo-1626621341517-bbf3d9990a23?auto=format&fit=crop&q=80&w=2000&ixlib=rb-4.0.3)',
                }}
            >
                <div className="absolute inset-0 bg-black/50 flex flex-col items-center justify-center text-white">
                    <h1 className="text-2xl md:text-6xl font-bold mb-4 text-center headerText text-primary">Discover Spiti Valley</h1>
                    <p className="text-sm md:text-xl mb-8 text-center max-w-2xl">
                        Explore the breathtaking landscapes and ancient monasteries of the Himalayan desert
                    </p>
                </div>
            </header>

            {/* Main Content */}
            <main className="max-w-7xl mx-auto px-4 py-12">
                <h2 className="text-3xl font-bold mb-8 text-center text-primary headerText">Popular Destinations</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {PopularData.map((destination) => (
                        <Link key={destination.id} to={`/destination/${destination.id}`}>
                            <DestinationCard destination={destination} />
                        </Link>
                    ))}
                </div>
            </main>

            {/* Call to Action */}
            <section className="bg-primary text-white py-16">
                <div className="max-w-7xl mx-auto px-4 text-center">
                    <h2 className="text-3xl font-bold mb-4 headerText">Ready to Explore Spiti Valley?</h2>
                    <p className="mb-8 text-lg">Book your adventure today and experience the magic of the Himalayas</p>
                    <a
                        href="/"
                        className="inline-block bg-white text-primary font-bold px-8 py-3 rounded-lg hover:bg-yellow-300 hover:text-white transition-colors"
                    >
                        Plan Your Trip
                    </a>
                </div>
            </section>
        </div>
    );
}

function DestinationCard({ destination }) {
    return (
        <div className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-xl transition-shadow">
            <img src={destination.image} alt={destination.name} className="w-full h-48 object-cover" />
            <div className="p-6">
                <h3 className="text-xl font-bold mb-2 text-gray-800">{destination.name}</h3>
                <p className="text-gray-600 mb-4 line-clamp-3">{destination.description}</p>
                <div className="flex items-center text-sm text-gray-500 mb-4">
                    <FiCalendar className="w-4 h-4 mr-2" />
                    <span>Best time: {destination.bestTimeToVisit}</span>
                </div>
                <div className="flex items-center justify-between">
                    <div className="text-primary font-semibold flex items-center">
                        Learn More <FiArrowRight className="w-4 h-4 ml-1" />
                    </div>
                    <div className="flex items-center text-sm text-gray-500">
                        <FiMapPin className="w-4 h-4 mr-1" />
                        <span>Spiti Valley</span>
                    </div>
                </div>
            </div>
        </div>
    );
}
