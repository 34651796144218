import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import { FaLocationDot } from 'react-icons/fa6';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { FaRegHeart } from 'react-icons/fa'; // Added for the heart icon

import BG from "../../assets/bg/9.png"

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const upcomingTours = [
    {
        title: "Winter Wonderland",
        region: "Spiti Valley",
        price: 100,
        image: "https://images.unsplash.com/photo-1516638121905-566d3c771f0f"
    },
    {
        title: "Spring Adventure",
        region: "Pin Valley",
        price: 150,
        image: "https://images.unsplash.com/photo-1506905925346-21bda4d32df4"
    },
    {
        title: "Summer Explorer",
        region: "Kaza Circuit",
        price: 120,
        image: "https://images.unsplash.com/photo-1493246507139-91e8fad9978e"
    },
    {
        title: "Monastery Trail",
        region: "Key Monastery",
        price: 200,
        image: "https://images.unsplash.com/photo-1519681393784-d120267933ba"
    },
    {
        title: "Autumn Expedition",
        region: "Chandratal Lake",
        price: 180,
        image: "https://images.unsplash.com/photo-1519681393784-d120267933ba"
    },
    {
        title: "Winter Trek",
        region: "Kibber Wildlife Sanctuary",
        price: 160,
        image: "https://images.unsplash.com/photo-1519681393784-d120267933ba"
    }
];

export default function ExploreSpiti() {
    return (
        <div className="relative w-full">
            {/* Background Image */}
            <img src={BG} alt="trekker" className="absolute top-0 right-0 -z-[10] sm:opacity-30 opacity-20 w-[100%] h-auto object-contain -translate-x-1/5 -translate-y-1/4" />

            {/* Content Container */}
            <div className="relative z-20 h-full flex flex-col justify-between p-12 pt-24 sm:p-24">
                {/* Header Content */}
                <div className="max-w-2xl mb-8 md:mb-12">
                    <h1 className="text-4xl font-extrabold sm:text-5xl md:text-7xl heroText text-black justify-center">
                        Explore
                        <strong className="block font-extrabold text-primary"> Spiti </strong>
                    </h1>

                    <p className="mt-4 max-w-xl text-sm sm:text-base md:text-lg text-gray-600">
                        Your One-stop portal to immersive experience into the high altitude Spiti Valley for the best of Himalayan Culture, Treks, Wildlife and Adventure Road trips with the local experts.
                    </p>
                </div>

                {/* Tours Slider */}
                <div className="w-full mt-2">
                    <Swiper
                        spaceBetween={16}
                        slidesPerView={2}
                        breakpoints={{
                            320: { slidesPerView: 2 },
                            480: { slidesPerView: 2 },
                            768: { slidesPerView: 3.2 },
                            1024: { slidesPerView: 4 },
                        }}
                        className="w-full"
                    >
                        {upcomingTours.map((tour, index) => (
                            <SwiperSlide key={index}>
                                <div className="relative aspect-square rounded-2xl overflow-hidden group cursor-pointer bg-gray-900">
                                    <img
                                        src={tour.image}
                                        alt={tour.title}
                                        className="absolute inset-0 w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
                                    />
                                    {/* Darker gradient overlay */}
                                    <div className="absolute inset-0 bg-gradient-to-t from-black/90 via-black/50 to-black/20" />

                                    {/* Heart Icon */}
                                    <button className="absolute top-3 right-3 p-2 rounded-full bg-black/20 hover:bg-black/40 transition-colors">
                                        <FaRegHeart className="text-white w-4 h-4" />
                                    </button>

                                    {/* Content */}
                                    <div className="absolute bottom-0 left-0 p-4 w-full">
                                        <div className="space-y-1">
                                            <h3 className="text-lg font-medium text-white group-hover:text-gray-200 transition-colors">
                                                {tour.title}
                                            </h3>
                                            <p className="text-sm text-gray-300">
                                                ${tour.price}<span className="text-gray-400">/person</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </div>
    );
}