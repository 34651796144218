// Define an array of objects for icon data
import TRAVEL from "../assets/icons/pathway.png";
import TREK from "../assets/icons/mountaineer.png";
import CAR from "../assets/icons/car.png";
import PLACES from "../assets/icons/landscape.png";
import WILDLIFE from "../assets/icons/wildlife.png";
import CULTURE from "../assets/icons/thai.png";

// Popular Destinations images

import DHANKAR from "../assets/places/dhankar1.webp";
import KEYM from "../assets/places/key1.jpeg";
import LANGZA from "../assets/places/langza.jpg";
import CHANDRATAAL from "../assets/places/chandrataal.jpg";
import SRIVER from "../assets/places/f3.jpg";
import TABO from "../assets/places/tabopaint.webp";
import NAKO from "../assets/places/nakolake.webp";


// Icon Data
export const iconData = [
    { src: TRAVEL, alt: "Tours", label: "Fixed Departures" },
    { src: TREK, alt: "Treks", label: "Treks" },
    { src: CAR, alt: "Car Hire", label: "Road Trips" },
    { src: PLACES, alt: "Places", label: "Places" },
    { src: WILDLIFE, alt: "Widlife", label: "Wildlife Expeditions" },
    { src: CULTURE, alt: "Culture", label: "Culture" },
];

// Home tours
// Home tours
export const tours = [
    {
        id: 1,
        imgSrc: 'https://via.placeholder.com/150',
        altText: 'Tour Mountains',
        title: 'Tour Mountains',
        description: 'Explore the serene beauty.',
        date: 'Sep 15',
        seats: '30',
        price: '5000',
    },
    {
        id: 2,
        imgSrc: 'https://via.placeholder.com/150',
        altText: 'City Lights',
        title: 'City Lights',
        description: 'Experience the vibrant city life.',
        date: 'Sep 22',
        seats: '50',
        price: '3500',
    },
    {
        id: 3,
        imgSrc: 'https://via.placeholder.com/150',
        altText: 'Beach Escape',
        title: 'Beach Escape',
        description: 'Relax at the stunning beaches.',
        date: 'Sep 28',
        seats: '40',
        price: '4500',
    },
    {
        id: 4,
        imgSrc: 'https://via.placeholder.com/150',
        altText: 'Desert',
        title: 'Desert',
        description: 'Discover the vast landscapes.',
        date: 'Oct 5',
        seats: '20',
        price: '6000',
    },
    {
        id: 5,
        imgSrc: 'https://via.placeholder.com/150',
        altText: 'Desert',
        title: 'Desert',
        description: 'Discover the vast landscapes.',
        date: 'Oct 5',
        seats: '20',
        price: '6000',
    },
    {
        id: 6,
        imgSrc: 'https://via.placeholder.com/150',
        altText: 'Desert',
        title: 'Desert',
        description: 'Discover the vast landscapes.',
        date: 'Oct 5',
        seats: '20',
        price: '6000',
    },
];

// Featured Tour Home
export const featuredTour = {
    bgImage: 'https://cdn.pixabay.com/photo/2018/09/01/17/42/himachal-3647248_1280.jpg', // Replace with your image URL
    slides: [
        {
            id: 1,
            title: 'Amazing Destination 1',
            description: 'Explore the wonders of Destination 1.',
        },
        {
            id: 2,
            title: 'Amazing Destination 2',
            description: 'Discover the beauty of Destination 2.',
        },
        {
            id: 3,
            title: 'Amazing Destination 3',
            description: 'Experience the excitement of Destination 3.',
        },
    ],
};

// Popular Destinations
export const destinations = [
    { name: 'Key Monastery', image: KEYM, colSpan: 'col-span-2', rowSpan: 'row-span-2' },
    { name: 'Nako', image: NAKO, colSpan: 'col-span-1', rowSpan: 'row-span-2' },
    { name: 'Tabo', image: TABO, colSpan: 'col-span-1', rowSpan: 'row-span-4' },
    { name: 'Langza', image: LANGZA, colSpan: 'col-span-1', rowSpan: 'row-span-2' },
    { name: 'Spiti River', image: SRIVER, colSpan: 'col-span-1', rowSpan: 'row-span-2' },
    { name: 'Dhankar', image: DHANKAR, colSpan: 'col-span-2', rowSpan: 'row-span-2' },
    { name: 'Chandrataal', image: CHANDRATAAL, colSpan: 'col-span-1', rowSpan: 'row-span-2' },
    // { name: 'Pin Valley', image: 'https://via.placeholder.com/300', colSpan: 'col-span-2', rowSpan: 'row-span-2' },
    // { name: 'Kunzum Pass', image: 'https://via.placeholder.com/300', colSpan: 'col-span-1', rowSpan: 'row-span-2' },
    // { name: 'Langza', image: 'https://via.placeholder.com/300', colSpan: 'col-span-1', rowSpan: 'row-span-2' },
];
// Add more destinations as needed

// Home Treks
export const treks = [
    {
        name: 'Kanamo Trek',
        image: 'https://images.pexels.com/photos/27126443/pexels-photo-27126443/free-photo-of-view-of-a-forested-valley.jpeg?auto=compress&cs=tinysrgb&w=600',
        description: 'An amazing high-altitude trek offering stunning views of the Himalayas and the chance to reach the summit of Kanamo Peak.',
        days: '8 Days',
        tech: 'Moderate'
    },
    {
        name: 'Chon Chon Khangdila Trek',
        image: 'https://images.pexels.com/photos/27126443/pexels-photo-27126443/free-photo-of-view-of-a-forested-valley.jpeg?auto=compress&cs=tinysrgb&w=600',
        description: 'A trek exploring the remote and less-traveled trails of Spiti Valley, with breathtaking landscapes and unique experiences.',
        days: '10 Days',
        tech: 'Challenging'
    },
    {
        name: 'Pin Bhaba Trek',
        image: 'https://images.pexels.com/photos/27126443/pexels-photo-27126443/free-photo-of-view-of-a-forested-valley.jpeg?auto=compress&cs=tinysrgb&w=600',
        description: 'A picturesque trek connecting the Pin Valley to the Kinnaur region, featuring diverse landscapes and rich flora and fauna.',
        days: '11 Days',
        tech: 'Moderate'
    },
    {
        name: 'Parangla Trek',
        image: 'https://images.pexels.com/photos/27126443/pexels-photo-27126443/free-photo-of-view-of-a-forested-valley.jpeg?auto=compress&cs=tinysrgb&w=600',
        description: 'An adventurous trek crossing the Parang La Pass, offering panoramic views and a true sense of wilderness in the Spiti Valley.',
        days: '9 Days',
        tech: 'Challenging'
    },
    {
        name: 'Pin Parvati Trek',
        image: 'https://images.pexels.com/photos/27126443/pexels-photo-27126443/free-photo-of-view-of-a-forested-valley.jpeg?auto=compress&cs=tinysrgb&w=600',
        description: 'A challenging trek connecting Pin Valley to the Parvati Valley, known for its diverse terrain and stunning landscapes.',
        days: '13 Days',
        tech: 'Difficult'
    },
    {
        name: 'Siri Chu Trek',
        image: 'https://images.pexels.com/photos/27126443/pexels-photo-27126443/free-photo-of-view-of-a-forested-valley.jpeg?auto=compress&cs=tinysrgb&w=600',
        description: 'A beautiful trek through lesser-known trails of Spiti, offering serene landscapes and an immersive experience in the valley.',
        days: '12 Days',
        tech: 'Moderate'
    },
];

// Home Blog

export const blogContent = [
    {
        id: 1,
        imageUrl: "https://images.pexels.com/photos/933054/pexels-photo-933054.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        title: "Exploring the Hidden Gems of Spiti Valley",
        category: "Travel Tips",
        description: "Discover the lesser-known trails and hidden spots in Spiti Valley that offer stunning views and an unforgettable experience. From remote villages to serene monasteries, embark on a journey that takes you off the beaten path.",
        date: "",
        type: "main",
    },
    {
        id: 2,
        imageUrl: "https://images.pexels.com/photos/5205083/pexels-photo-5205083.jpeg?auto=compress&cs=tinysrgb&w=600",
        title: "New Road Development Project in Spiti Valley Underway",
        category: "",
        description: "",
        date: "Sept 2",
        type: "news",
    },
    {
        id: 3,
        imageUrl: "https://images.pexels.com/photos/20459273/pexels-photo-20459273/free-photo-of-woman-in-tibetan-dress.jpeg?auto=compress&cs=tinysrgb&w=600",
        title: "Spiti Valley Hosts Annual Cultural Festival",
        category: "",
        description: "",
        date: "Aug 25",
        type: "news",
    },
    {
        id: 4,
        imageUrl: "https://images.pexels.com/photos/27100608/pexels-photo-27100608/free-photo-of-alpine-ibexes-walking-on-a-rocky-hill.jpeg?auto=compress&cs=tinysrgb&w=600",
        title: "Wildlife Conservation Efforts in Spiti Valley Strengthened",
        category: "",
        description: "",
        date: "Aug 18",
        type: "news",
    },
    {
        id: 5,
        imageUrl: "https://images.pexels.com/photos/1303431/pexels-photo-1303431.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        title: "Spiti Valley Treks: A Guide to the Best Trails",
        category: "",
        description: "",
        date: "July 23",
        type: "news",
    },
    {
        id: 6,
        imageUrl: "https://images.pexels.com/photos/750895/pexels-photo-750895.jpeg?auto=compress&cs=tinysrgb&w=600",
        title: "Immersing Yourself in the Rich Culture of Spiti Valley",
        category: "Culture",
        description: "Spiti Valley is not just about breathtaking landscapes but also about its rich cultural heritage. Learn about the traditions, festivals, and daily life of the locals that make this valley a unique and vibrant destination.",
        date: "",
        type: "main",
    },
];

// Adventure
export const adventures = [
    {
        name: 'Ice Climbing',
        image: 'https://images.pexels.com/photos/3046622/pexels-photo-3046622.jpeg?auto=compress&cs=tinysrgb&w=600',
    },
    {
        name: 'Rock Climbing',
        image: 'https://images.pexels.com/photos/4496093/pexels-photo-4496093.jpeg?auto=compress&cs=tinysrgb&w=600',
    },
    {
        name: 'Downhill Cycling',
        image: 'https://images.pexels.com/photos/1090935/pexels-photo-1090935.jpeg?auto=compress&cs=tinysrgb&w=600',
    },
    {
        name: 'Camping & Stargazing',
        image: 'https://images.pexels.com/photos/1565982/pexels-photo-1565982.jpeg?auto=compress&cs=tinysrgb&w=600',
    },
    {
        name: 'Yak Safari',
        image: 'https://images.pexels.com/photos/1080810/pexels-photo-1080810.jpeg?auto=compress&cs=tinysrgb&w=600',
    },
    {
        name: 'Snow Leopard Tracking',
        image: 'https://images.pexels.com/photos/2990414/pexels-photo-2990414.jpeg?auto=compress&cs=tinysrgb&w=600',
    },
    {
        name: 'Village Homestay Experience',
        image: 'https://images.pexels.com/photos/1546166/pexels-photo-1546166.jpeg?auto=compress&cs=tinysrgb&w=600',
    },
    {
        name: 'Winter Trekking',
        image: 'https://images.pexels.com/photos/104827/pexels-photo-104827.jpeg?auto=compress&cs=tinysrgb&w=600',
    },
    {
        name: 'Buddhist Monastery Tours',
        image: 'https://images.pexels.com/photos/2565379/pexels-photo-2565379.jpeg?auto=compress&cs=tinysrgb&w=600',
    },
    {
        name: 'Mountain Photography Expeditions',
        image: 'https://images.pexels.com/photos/1687830/pexels-photo-1687830.jpeg?auto=compress&cs=tinysrgb&w=600',
    },
    {
        name: 'High Altitude Nature Walks',
        image: 'https://images.pexels.com/photos/2170211/pexels-photo-2170211.jpeg?auto=compress&cs=tinysrgb&w=600',
    },
    {
        name: 'Chadar Trek',
        image: 'https://images.pexels.com/photos/248874/pexels-photo-248874.jpeg?auto=compress&cs=tinysrgb&w=600',
    },
];

// FAQ data stored in an array
export const faqData = [
    {
        question: 'Which are the best Spiti tour packages?',
        answer:
            'There are a plethora of Spiti Valley tour packages available which can be differentiated on the basis of number of days and the locations that they take you to. Though all Spiti Valley tour packages offer something different, according to travellers the best Spiti Valley tour packages are those which take you to the wonderland during the summer months.',
    },
    {
        question: 'Which are the best summer Spiti tour packages?',
        answer:
            'Some of the best summer Spiti Valley tour packages are Astounding Spiti Valley tour for 5 nights and 6 days, Spiti Valley Adventure for 6 nights and 7 days, and Spiti Valley Excursion from Pune for 5 nights and 6 days. You can check out our other Spiti Valley tour packages and choose the one that suits you the best.',
    },
    {
        question: 'What is the best time to go for Spiti Valley road trip?',
        answer:
            'The best time to go for a road trip in a Spiti Valley tour is during the summer months of May to October since the roads get covered with sheets of snow during the winters.',
    },
    {
        question: 'How much does a trip to Spiti valley cost?',
        answer:
            'A Spiti Valley tour can cost anywhere between INR 23,000 to INR 40,000 per person. The cost usually depends on the number of days, locations covered, and the facilities that will be provided in a Spiti Valley tour package.',
    },
    {
        question: 'How to reach Spiti valley from Shimla?',
        answer:
            'The distance between Spiti Valley and Shimla is almost 450 kilometers which usually takes almost 11 hours to cover. One can either book a cab or get onboard a local bus to reach Spiti Valley from Shimla.',
    },
    {
        question: 'What are the famous monasteries in Lahaul-Spiti?',
        answer:
            'Some of the most famous monasteries in Lahaul- Spiti are Key Monastery, Dhankar Monastery, Gemur Monastery, and Kungri Monastery.',
    },
    {
        question: 'What are the famous rivers in Lahaul-Spiti?',
        answer:
            'Some of the most famous rivers in Lahaul Spiti are Spiti river, Pin river, Bhaga, and Parang river.',
    },
    {
        question: 'Which are the best places to stay in Spiti Valley?',
        answer:
            'Some of the best places to stay in during a Spiti Valley tour are The Nomad’s Cottage, Peylang Homestay, Mochilerro Ostello, and Hotel Spiti Heritage.',
    },
    {
        question: 'What should I wear in Lahaul-Spiti?',
        answer:
            'The weather is usually cold throughout the year, therefore when on a Lahaul Spiti Valley tour you must wear warm clothes like thermals, fleece shirts, hoodies/jackets, and it is highly recommended to carry a pair of sunglasses.',
    },
    {
        question: 'Does it snow in Spiti?',
        answer:
            'Yes, it snows in Spiti during the winter. The snowfall in Spiti is quite famous among adventure travellers, therefore we also recommend checking out our Winter Spiti Valley Tour Packages for an unparalleled experience.',
    },
    {
        question: 'What are the best places to visit in Spiti?',
        answer:
            'Some of the best places to visit during a Spiti Valley tour are Langza Village, Hikkim, Key Monastery, and Pin Valley National Park.',
    },
];


