import TOUR1 from "../../assets/images/snowleopard.jpg"

export const toursData = [
    {
        id: 1,
        title: "Winter Snow Leopard Safari",
        description: "Experience the thrill of spotting the elusive snow leopard in its natural habitat.",
        images: [
            TOUR1,
            "https://spitivalleytours.com/wp-content/uploads/2018/04/snow-leopard-768x492.jpg",
            TOUR1,
        ],
        highlights: [
            "Track snow leopards with expert guides",
            "Visit remote Himalayan villages",
            "Experience the unique winter landscape of Spiti"
        ],
        startDate: "15-Jan",
        duration: "10 days",
        groupSize: "4-8",
        difficultyLevel: "Challenging",
        price: {
            regular: "Price on request",
            discounted: "Price on request"
        },
        itinerary: [
            {
                day: 1,
                title: "Arrival in Shimla",
                description: "Acclimatize to the altitude and prepare for the journey ahead.",
                activities: ["Welcome dinner", "Briefing about the safari"]
            },
            {
                day: 2,
                title: "Drive to Reckong Peo",
                description: "Scenic drive through Kinnaur Valley.",
                activities: ["Visit Sarahan", "Explore Reckong Peo"]
            }
            // Add more days as needed
        ],
        reviews: [
            {
                user: "Wildlife Enthusiast",
                rating: 5,
                comment: "Once-in-a-lifetime experience! Spotted two snow leopards!"
            },
            {
                user: "Adventure Seeker",
                rating: 4,
                comment: "Challenging but rewarding. The landscapes are breathtaking."
            }
        ],
        faqs: [
            {
                question: "What's the best time for snow leopard sightings?",
                answer: "The best time is from January to March when snow leopards descend to lower altitudes."
            },
            {
                question: "How physically demanding is this tour?",
                answer: "This tour is challenging due to high altitudes and cold temperatures. Good physical fitness is required."
            }
        ]
    },
    {
        id: 2,
        title: "Manali – Spiti Tour",
        description: "A quick yet comprehensive tour of Spiti Valley starting from Manali.",
        images: [
            "https://spitivalleytours.com/wp-content/uploads/2018/04/IMG_0207-768x512.jpg",
            "https://images.unsplash.com/photo-1626621341714-6e5d8a1d52c8?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8c3BpdGklMjB2YWxsZXl8ZW58MHx8MHx8fDA%3D",
            "https://images.unsplash.com/photo-1573490647695-2eb0f60ea9d9?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8c3BpdGklMjB2YWxsZXl8ZW58MHx8MHx8fDA%3D"
        ],
        highlights: [
            "Cross Rohtang Pass",
            "Visit Key Monastery",
            "Explore Chandratal Lake"
        ],
        startDate: "2025-06-01",
        duration: "5 days",
        groupSize: "4-12",
        difficultyLevel: "Moderate",
        price: {
            regular: "Price on request",
            discounted: "Price on request"
        },
        itinerary: [
            {
                day: 1,
                title: "Manali to Kaza",
                description: "Drive through the scenic Rohtang Pass to reach Kaza.",
                activities: ["Visit Kunzum Pass", "Evening walk in Kaza"]
            },
            {
                day: 2,
                title: "Kaza to Key Monastery",
                description: "Explore the famous Key Monastery and nearby villages.",
                activities: ["Visit Key Monastery", "Explore Kibber village"]
            }
            // Add more days as needed
        ],
        reviews: [
            {
                user: "Mountain Lover",
                rating: 5,
                comment: "Perfect short trip to experience the beauty of Spiti!"
            },
            {
                user: "First-time Trekker",
                rating: 4,
                comment: "Great introduction to Spiti Valley. Wish it was a bit longer!"
            }
        ],
        faqs: [
            {
                question: "Is 5 days enough to explore Spiti?",
                answer: "While 5 days provides a good introduction to Spiti, a longer trip allows for a more in-depth experience."
            },
            {
                question: "How is the road condition from Manali to Kaza?",
                answer: "The road can be challenging with some rough patches. Our experienced drivers ensure a safe journey."
            }
        ]
    },
    {
        id: 3,
        title: "Ladakh Road Trip",
        description: "An adventurous road trip through the stunning landscapes of Ladakh.",
        images: [
            "https://spitivalleytours.com/wp-content/uploads/2018/12/IMG_3708-768x512.jpg",
            "https://images.unsplash.com/photo-1590689080414-acf7b5a7e061?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8bGFkYWtofGVufDB8fDB8fHww",
            "https://images.unsplash.com/photo-1621996659490-3275b4d0d951?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8bGFkYWtofGVufDB8fDB8fHww"
        ],
        highlights: [
            "Visit Pangong Lake",
            "Explore Nubra Valley",
            "Experience local culture in Leh"
        ],
        startDate: "2024-07-01",
        duration: "8 days",
        groupSize: "6-12",
        difficultyLevel: "Moderate",
        price: {
            regular: "Price on request",
            discounted: "Price on request"
        },
        itinerary: [
            {
                day: 1,
                title: "Arrival in Leh",
                description: "Acclimatize to the high altitude of Leh.",
                activities: ["Rest and acclimatization", "Short walk around Leh market"]
            },
            {
                day: 2,
                title: "Leh to Nubra Valley",
                description: "Drive over Khardung La, one of the highest motorable passes.",
                activities: ["Visit Diskit Monastery", "Camel ride at Hunder sand dunes"]
            }
            // Add more days as needed
        ],
        reviews: [
            {
                user: "Photography Enthusiast",
                rating: 5,
                comment: "Incredible landscapes at every turn. A photographer's paradise!"
            },
            {
                user: "Culture Explorer",
                rating: 4,
                comment: "Loved the mix of natural beauty and cultural experiences."
            }
        ],
        faqs: [
            {
                question: "Do I need a special permit for Ladakh?",
                answer: "Indian nationals don't need a permit, but foreign nationals require an Inner Line Permit for certain areas."
            },
            {
                question: "How do we handle high altitude sickness?",
                answer: "We include acclimatization days and our guides are trained to assist with altitude-related issues."
            }
        ]
    },
    {
        id: 4,
        title: "Kinnaur & Spiti Innova Tour",
        description: "Comfortable journey through Kinnaur and Spiti valleys in Toyota Innova.",
        images: [
            "https://spitivalleytours.com/wp-content/uploads/2018/09/IMG_0550-768x512.jpg",
            "https://images.unsplash.com/photo-1573490647695-2eb0f60ea9d9?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8c3BpdGklMjB2YWxsZXl8ZW58MHx8MHx8fDA%3D",
            "https://images.unsplash.com/photo-1609766418204-94aae0ecf4e5?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHNwaXRpJTIwdmFsbGV5fGVufDB8fDB8fHww"
        ],
        highlights: [
            "Comfortable travel in Toyota Innova",
            "Visit Kalpa and Chitkul in Kinnaur",
            "Explore Key Monastery and Dhankar in Spiti"
        ],
        startDate: "2024-05-15",
        duration: "8 days",
        groupSize: "4-6",
        difficultyLevel: "Easy to Moderate",
        price: {
            regular: "Price on request",
            discounted: "Price on request"
        },
        itinerary: [
            {
                day: 1,
                title: "Shimla to Sarahan",
                description: "Drive through beautiful Kinnaur Valley.",
                activities: ["Visit Bhimakali Temple in Sarahan", "Overnight in Sarahan"]
            },
            {
                day: 2,
                title: "Sarahan to Chitkul",
                description: "Visit the last inhabited village near the Indo-Tibet border.",
                activities: ["Explore Sangla Valley", "Evening in Chitkul"]
            }
            // Add more days as needed
        ],
        reviews: [
            {
                user: "Comfort Traveler",
                rating: 5,
                comment: "Loved the comfort of Innova while exploring remote areas!"
            },
            {
                user: "Scenic Route Lover",
                rating: 4,
                comment: "Beautiful journey through Kinnaur and Spiti. Well-paced itinerary."
            }
        ],
        faqs: [
            {
                question: "Why Toyota Innova for this tour?",
                answer: "Innova provides comfort and stability on mountain roads, making the journey more enjoyable."
            },
            {
                question: "Is this tour suitable for elderly travelers?",
                answer: "Yes, the comfort of Innova and the easy to moderate difficulty level makes it suitable for most age groups."
            }
        ]
    },
    {
        id: 5,
        title: "Kinnaur & Spiti",
        description: "A week-long adventure exploring the best of Kinnaur and Spiti valleys.",
        images: [
            "https://spitivalleytours.com/wp-content/uploads/2018/04/IMG_20170714_183337826-768x576.jpg",
            "https://images.unsplash.com/photo-1626621341714-6e5d8a1d52c8?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8c3BpdGklMjB2YWxsZXl8ZW58MHx8MHx8fDA%3D",
            "https://images.unsplash.com/photo-1573490647695-2eb0f60ea9d9?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8c3BpdGklMjB2YWxsZXl8ZW58MHx8MHx8fDA%3D"
        ],
        highlights: [
            "Visit Kalpa and Nako Lake",
            "Explore Tabo Monastery",
            "Experience local life in Kaza"
        ],
        startDate: "2024-06-10",
        duration: "7 days",
        groupSize: "6-12",
        difficultyLevel: "Moderate",
        price: {
            regular: "Price on request",
            discounted: "Price on request"
        },
        itinerary: [
            {
                day: 1,
                title: "Shimla to Rampur",
                description: "Start your journey from Shimla to Rampur.",
                activities: ["Drive along Sutlej River", "Overnight in Rampur"]
            },
            {
                day: 2,
                title: "Rampur to Kalpa",
                description: "Enter the beautiful Kinnaur Valley.",
                activities: ["Visit Recong Peo", "Evening in Kalpa with views of Kinner Kailash"]
            }
            // Add more days as needed
        ],
        reviews: [
            {
                user: "Nature Enthusiast",
                rating: 5,
                comment: "Perfect blend of Kinnaur and Spiti. Loved every moment!"
            },
            {
                user: "Cultural Explorer",
                rating: 4,
                comment: "Great insights into local culture and traditions. Wish we had more time in some places."
            }
        ],
        faqs: [
            {
                question: "What's the best time to take this tour?",
                answer: "The best time is from June to September when the weather is most favorable and all routes are open."
            },
            {
                question: "How challenging is the terrain?",
                answer: "The terrain varies from easy to moderate. Some areas have rough roads, but our vehicles are well-equipped for the journey."
            }
        ]
    },
    {
        id: 6,
        title: "Jeep Safari Chandigarh – Kinnaur – Spiti",
        description: "An extensive jeep safari covering Chandigarh, Kinnaur, and Spiti regions.",
        images: [
            "https://spitivalleytours.com/wp-content/uploads/2018/04/IMG_0445-768x486.jpg",
            "https://images.unsplash.com/photo-1626621341670-a4f94c0c3e31?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c3BpdGklMjB2YWxsZXl8ZW58MHx8MHx8fDA%3D",
            "https://images.unsplash.com/photo-1626621341714-6e5d8a1d52c8?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8c3BpdGklMjB2YWxsZXl8ZW58MHx8MHx8fDA%3D"
        ],
        highlights: [
            "Start from Chandigarh",
            "Explore Kinnaur's hidden gems",
            "Comprehensive tour of Spiti Valley"
        ],
        startDate: "2024-07-05",
        duration: "10 days",
        groupSize: "4-8",
        difficultyLevel: "Moderate to Challenging",
        price: {
            regular: "Price on request",
            discounted: "Price on request"
        },
        itinerary: [
            {
                day: 1,
                title: "Chandigarh to Narkanda",
                description: "Begin your journey from Chandigarh to the hill station of Narkanda.",
                activities: ["Drive through Shivalik Hills", "Evening at Narkanda"]
            },
            {
                day: 2,
                title: "Narkanda to Sarahan",
                description: "Enter Kinnaur Valley and reach Sarahan.",
                activities: ["Visit Hatu Peak", "Explore Bhimakali Temple in Sarahan"]
            }
            // Add more days as needed
        ],
        reviews: [
            {
                user: "Adventure Seeker",
                rating: 5,
                comment: "Incredible journey! The jeep safari added an extra thrill to the experience."
            },
            {
                user: "Photography Enthusiast",
                rating: 4,
                comment: "Amazing photo opportunities throughout the trip. A bit challenging at times but worth it!"
            }
        ],
        faqs: [
            {
                question: "How comfortable are the jeeps?",
                answer: "We use well-maintained, comfortable jeeps suitable for mountain terrain. They offer a good balance of comfort and off-road capability."
            },
            {
                question: "Is this tour suitable for families?",
                answer: "This tour is more suited for adventure enthusiasts and those comfortable with long drives and varying road conditions."
            }
        ]
    },
    {
        id: 7,
        title: "Jeep Safari – Shimla – Kinnaur – Spiti",
        description: "A comprehensive jeep safari starting from Shimla, covering Kinnaur and Spiti valleys.",
        images: [
            "https://images.unsplash.com/photo-1626621341517-bbf3d9990a23?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8c3BpdGklMjB2YWxsZXl8ZW58MHx8MHx8fDA%3D",
            "https://images.unsplash.com/photo-1573490647695-2eb0f60ea9d9?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8c3BpdGklMjB2YWxsZXl8ZW58MHx8MHx8fDA%3D",
            "https://images.unsplash.com/photo-1609766418204-94aae0ecf4e5?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHNwaXRpJTIwdmFsbGV5fGVufDB8fDB8fHww"
        ],
        highlights: [
            "Start from the colonial town of Shimla",
            "Explore remote villages of Kinnaur",
            "Visit all major attractions of Spiti Valley"
        ],
        startDate: "2024-08-01",
        duration: "10 days",
        groupSize: "4-8",
        difficultyLevel: "Moderate to Challenging",
        price: {
            regular: "Price on request",
            discounted: "Price on request"
        },
        itinerary: [
            {
                day: 1,
                title: "Shimla to Sarahan",
                description: "Drive from Shimla to Sarahan, entering Kinnaur Valley.",
                activities: ["Visit Rampur Bushahr", "Evening at Bhimakali Temple"]
            },
            {
                day: 2,
                title: "Sarahan to Chitkul",
                description: "Journey to Chitkul, the last inhabited village near Indo-Tibet border.",
                activities: ["Explore Sangla Valley", "Evening walk in Chitkul"]
            }
            // Add more days as needed
        ],
        reviews: [
            {
                user: "Mountain Explorer",
                rating: 5,
                comment: "Unforgettable journey through some of the most beautiful landscapes I've ever seen!"
            },
            {
                user: "Culture Buff",
                rating: 4,
                comment: "Loved the mix of natural beauty and cultural experiences. The jeep ride was an adventure in itself."
            }
        ],
        faqs: [
            {
                question: "How different is this from the Chandigarh start tour?",
                answer: "This tour starts from Shimla, allowing more time in the mountains. It's ideal for those who want to skip the plains and start their adventure right from the hills."
            },
            {
                question: "What type of accommodation can we expect?",
                answer: "Accommodation varies from comfortable hotels in towns to basic guesthouses in remote areas, giving you a true taste of local hospitality."
            }
        ]
    }
];

export default toursData;