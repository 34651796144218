import React from 'react'
import Hero from '../components/home/Hero'
import Icon from '../components/home/Icon'
import TourSlider from '../components/home/TourSlider'
import Featured from '../components/home/Featured'
import PopularDestinations from '../components/home/PopularDestinations'
import TrekSlider from '../components/home/TrekSlider'
import Adventure from '../components/home/Adventure'
import LeopardCTA from '../components/home/LeopardCTA'
import BlogComponent from '../components/home/BlogComponent'
import WhyUs from '../components/home/WhyUs'
import FAQSection from '../components/FAQSection'
import TestComponent from '../components/testtour/TestComponent'
import TestSlider from '../components/testtour/TestSlider'
import TrekkingSlider from '../components/trekPage/TrekkingSlider'
import ExploreSpiti from '../components/home/ExploreSpiti'

export default function Home() {
    return (
        <>
            {/* <Hero /> */}
            <ExploreSpiti />
            {/* <Icon /> */}
            <TourSlider />
            <TestSlider />
            {/* <Featured /> */}
            <PopularDestinations />
            {/* <TrekSlider /> */}
            <TrekkingSlider />
            {/* <Adventure /> */}
            <LeopardCTA />
            {/* <BlogComponent /> */}
            <WhyUs />
            <FAQSection />
        </>
    )
}
