import React, { useState, useEffect } from 'react';
import { MdPhone, MdEmail, MdChat, MdWhatsapp } from 'react-icons/md';
import ContactModal from './ContactModal'; // Modal for email

const FloatingButton = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Toggle floating menu
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    // Show button on scroll
    const handleScroll = () => {
        if (window.pageYOffset > 50) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Handle modal open/close for email contact
    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    return (
        <div className='md:block hidden'>
            {isVisible && (
                <div className="fixed bottom-10 right-10 transition-opacity duration-500 ease-in-out z-10">
                    {/* Floating Button */}
                    <div className="relative">
                        <button
                            onClick={toggleMenu}
                            className="w-12 h-12 bg-orange-500 text-white rounded-full shadow-lg flex justify-center items-center text-2xl focus:outline-none"
                        >
                            <MdChat />
                        </button>

                        {/* Menu Items */}
                        <div
                            className={`absolute bottom-16 flex flex-col items-center space-y-4 transition-all duration-300 ${isOpen ? 'translate-x-0 opacity-100' : 'translate-x-10 opacity-0'
                                }`}
                        >
                            {/* Phone Icon - Call */}
                            <a
                                href="tel:+917649920808" // Replace with the actual phone number
                                className={`transform transition-all duration-300 ${isOpen ? 'translate-x-0 delay-200' : 'translate-x-10'
                                    } bg-blue-400 hover:bg-blue-500 w-12 h-12 rounded-full shadow-md flex items-center justify-center text-white text-xl`}
                            >
                                <MdPhone />
                            </a>

                            {/* Email Icon - Contact Us Modal */}
                            <button
                                onClick={toggleModal}
                                className={`transform transition-all duration-300 ${isOpen ? 'translate-x-0 delay-300' : 'translate-x-10'
                                    } bg-yellow-500 hover:bg-yellow-600 w-12 h-12 rounded-full shadow-md flex items-center justify-center text-white text-xl`}
                            >
                                <MdEmail />
                            </button>

                            {/* WhatsApp Icon - WhatsApp API */}
                            <a
                                href="https://api.whatsapp.com/send/?phone=%2B917649920808" // Replace with the actual WhatsApp number
                                target="_blank"
                                rel="noopener noreferrer"
                                className={`transform transition-all duration-300 ${isOpen ? 'translate-x-0 delay-400' : 'translate-x-10'
                                    }  bg-green-500 hover:bg-green-600 w-12 h-12 rounded-full shadow-md flex items-center justify-center text-white text-xl`}
                            >
                                <MdWhatsapp />
                            </a>
                        </div>
                    </div>
                </div>
            )}

            {/* Modal for Email */}
            {isModalOpen && <ContactModal isOpen={isModalOpen} onClose={toggleModal} />}
        </div>
    );
};

export default FloatingButton;
