import React from "react";
import { MdEmail, MdPhone, MdLocationOn } from "react-icons/md";

const ContactUs = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-yellow-100 to-yellow-300 flex items-center justify-center">
      <div className=" w-full">

        {/* Hero Section */}
        <div className="relative h-[60vh] bg-cover bg-center" style={{ backgroundImage: 'url(https://images.unsplash.com/photo-1464822759023-fed622ff2c3b?auto=format&fit=crop&q=80&w=2000)' }}>
          <div className="absolute inset-0 bg-black/50 flex flex-col items-center justify-center text-white">
            <h1 className="text-2xl md:text-5xl font-bold mb-4 text-center text-primary headerText">Get In Touch</h1>
            <p className="text-sm md:text-xl mb-8 text-center max-w-2xl">We’d love to hear from you! Fill out the form below or reach out directly.</p>

          </div>
        </div>

        <div className="grid md:grid-cols-2 gap-8 m-8 px-8">
          {/* Contact Details */}
          <div className="bg-white shadow-lg rounded-lg p-8">
            <h3 className="text-2xl font-semibold text-gray-800 mb-6 headerText text-primary">Contact Details</h3>
            <div className="space-y-4">
              <ContactDetail
                icon={<MdEmail />}
                label="Email"
                value="spitiexplore@gmail.com"
                link="mailto:spitiexplore@gmail.com"
              />
              <ContactDetail
                icon={<MdPhone />}
                label="Phone"
                value="+91 76499 20808"
                link="tel:+9176499 20808"
              />
              <ContactDetail
                icon={<MdLocationOn />}
                label="Location"
                value="Spiti Valley, Himachal Pradesh, India"
              />
            </div>
          </div>

          {/* Contact Form */}
          <div className="bg-white shadow-lg rounded-lg p-8">
            <h3 className="text-2xl font-semibold text-gray-800 mb-6 headerText text-primary">Send Us a Message</h3>
            <form className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-600">Name</label>
                <input
                  type="text"
                  placeholder="Your Name"
                  className="w-full mt-2 px-4 py-3 border rounded-lg focus:ring-2 focus:ring-primary focus:outline-none"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-600">Email</label>
                <input
                  type="email"
                  placeholder="Your Email"
                  className="w-full mt-2 px-4 py-3 border rounded-lg focus:ring-2 focus:ring-primary focus:outline-none"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-600">Message</label>
                <textarea
                  placeholder="Your Message"
                  rows="5"
                  className="w-full mt-2 px-4 py-3 border rounded-lg focus:ring-2 focus:ring-primary focus:outline-none"
                ></textarea>
              </div>
              <button
                type="submit"
                className="w-full bg-primary text-white py-3 rounded-lg font-semibold hover:bg-primary-dark transition"
              >
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const ContactDetail = ({ icon, label, value, link }) => (
  <div className="flex items-center gap-4">
    <div className="text-3xl text-primary">{icon}</div>
    <div>
      <p className="text-sm text-gray-600">{label}</p>
      {link ? (
        <a href={link} className="text-lg font-medium text-gray-800 hover:underline">
          {value}
        </a>
      ) : (
        <p className="text-lg font-medium text-gray-800">{value}</p>
      )}
    </div>
  </div>
);

export default ContactUs;
