import React from "react";

const TourHero = () => {
    return (
        <main className="">
            {/* Hero Section */}
            <section className="relative text-white md:text-left text-center overflow-hidden">
                {/* Overlay */}
                <div className="absolute inset-0">
                    {/* Background Image */}
                    <div
                        className="absolute inset-0 bg-cover bg-center blur-[0.9px]"
                        style={{
                            backgroundImage:
                                "url('https://images.pexels.com/photos/16443956/pexels-photo-16443956/free-photo-of-milkyway-behind-the-chorten.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')",
                        }}
                    ></div></div>

                {/* Content */}
                <div className="container mx-auto px-4 py-24 md:py-32 relative z-0">
                    <div className="flex flex-col md:flex-row items-center justify-between">
                        {/* Left Side: Company Info */}
                        <div className="w-full md:w-1/2 mb-12 md:mb-0">
                            <h1 className="text-5xl md:text-6xl font-bold mb-6 leading-tight tourHeroText text-primary">
                                Explore.<br />Spiti.<br />Today.
                            </h1>
                            <p className="text-xl mb-8">
                                Your One-stop portal to immersive experience into the high altitude Spiti Valley
                            </p>
                            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                                <a
                                    href="/"
                                    className="block w-full rounded bg-primary px-12 py-3 text-sm font-medium text-white shadow hover:bg-secondary focus:outline-none focus:ring active:bg-primary sm:w-auto"
                                >
                                    Get Started
                                </a>

                                <a
                                    href="/"
                                    className="block w-full rounded bg-white px-12 py-3 text-sm font-medium text-primary shadow hover:text-secondary focus:outline-none focus:ring active:text-primary sm:w-auto"
                                >
                                    Learn More
                                </a>
                            </div>
                        </div>

                        {/* Right Side: Features */}
                        <div className="w-full md:w-1/5">
                            <div className="bg-white/10 bg-opacity-10 backdrop-filter backdrop-blur-sm text-white rounded-xl p-8 shadow-2xl">
                                <div className="text-2xl font-semibold mb-2">Why us?</div>
                                <ul className="sm:space-y-1 sm:block flex items-center">
                                    <li className="flex items-center ">
                                        <svg
                                            className="w-6 h-6 mr-3 text-yellow-400"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M13 10V3L4 14h7v7l9-11h-7z"
                                            />
                                        </svg>
                                        <span>Industry Leaders</span>
                                    </li>
                                    <li className="flex items-center">
                                        <svg
                                            className="w-6 h-6 mr-3 text-green-400"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                                            />
                                        </svg>
                                        <span>Safety First </span>
                                    </li>
                                    <li className="flex items-center">
                                        <svg
                                            className="w-6 h-6 mr-3 text-purple-400"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01"
                                            />
                                        </svg>
                                        <span>Customized Tours</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Decorative Element */}
                <div className="absolute bottom-0 left-0 right-0">
                    <svg
                        viewBox="0 0 1440 120"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 120L60 105C120 90 240 60 360 45C480 30 600 30 720 37.5C840 45 960 60 1080 67.5C1200 75 1320 75 1380 75L1440 75V120H1380C1320 120 1200 120 1080 120C960 120 840 120 720 120C600 120 480 120 360 120C240 120 120 120 60 120H0Z"
                            fill="white"
                        />
                    </svg>
                </div>
            </section>
        </main>
    );
};

export default TourHero;
