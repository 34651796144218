import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FaCalendar, FaClock, FaUsers, FaMapMarkerAlt, FaStar, FaComments } from 'react-icons/fa';

const TourDetails = () => {
    const [tour, setTour] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        const fetchTour = async () => {
            try {
                const response = await fetch(`https://explorespiti-backend.vercel.app/api/tours/${id}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch tour details');
                }
                const data = await response.json();
                setTour(data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchTour();
    }, [id]);

    if (loading) return <div className="text-center py-10 text-white">Loading...</div>;
    if (error) return <div className="text-center py-10 text-red-500">{error}</div>;
    if (!tour) return <div className="text-center py-10 text-white">Tour not found</div>;

    return (
        <div className="min-h-screen bg-black text-white">
            {/* Hero Section */}
            <div className="relative h-screen">
                <img src={tour.images[0]} alt={tour.title} className="w-full h-full object-cover" />
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black">
                    <div className="container mx-auto px-4 h-full flex flex-col justify-end pb-20">
                        <h1 className="text-4xl md:text-6xl font-bold mb-4">{tour.title}</h1>
                        <p className="text-xl mb-8">{tour.description}</p>
                        <button className="bg-yellow-500 text-black font-bold py-2 px-6 rounded-full hover:bg-yellow-400 transition duration-300">
                            Book Now
                        </button>
                    </div>
                </div>
            </div>

            {/* Tour Details */}
            <div className="container mx-auto px-4 py-16">
                <div className="flex flex-wrap -mx-4">
                    <div className="w-full lg:w-2/3 px-4 mb-8 lg:mb-0">
                        <div className="bg-gray-900 rounded-lg p-6 mb-8">
                            <h2 className="text-2xl font-bold mb-4">Tour Highlights</h2>
                            <ul className="list-disc list-inside">
                                {tour.highlights.map((highlight, index) => (
                                    <li key={index} className="mb-2">{highlight}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="bg-gray-900 rounded-lg p-6">
                            <h2 className="text-2xl font-bold mb-4">Itinerary</h2>
                            {tour.itinerary.map((day, index) => (
                                <div key={index} className="mb-6 last:mb-0">
                                    <h3 className="text-xl font-semibold mb-2">Day {day.day}: {day.title}</h3>
                                    <p className="mb-2">{day.description}</p>
                                    <ul className="list-disc list-inside">
                                        {day.activities.map((activity, actIndex) => (
                                            <li key={actIndex}>{activity}</li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="w-full lg:w-1/3 px-4">
                        <div className="bg-gray-900 rounded-lg p-6 sticky top-4">
                            <h2 className="text-2xl font-bold mb-4">Tour Details</h2>
                            <div className="space-y-4">
                                <div className="flex items-center">
                                    <FaCalendar className="mr-2" />
                                    <span>Start Date: {new Date(tour.startDate).toLocaleDateString()}</span>
                                </div>
                                <div className="flex items-center">
                                    <FaClock className="mr-2" />
                                    <span>Duration: {tour.duration}</span>
                                </div>
                                <div className="flex items-center">
                                    <FaUsers className="mr-2" />
                                    <span>Group Size: {tour.groupSize}</span>
                                </div>
                                <div className="flex items-center">
                                    <FaMapMarkerAlt className="mr-2" />
                                    <span>Difficulty: {tour.difficultyLevel}</span>
                                </div>
                                <div className="text-2xl font-bold">
                                    Price: ₹{tour.price.discounted}
                                    {tour.price.regular !== tour.price.discounted && (
                                        <span className="text-lg line-through text-gray-500 ml-2">₹{tour.price.regular}</span>
                                    )}
                                </div>
                                <button className="w-full bg-yellow-500 text-black font-bold py-2 px-4 rounded hover:bg-yellow-400 transition duration-300">
                                    Book Now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Reviews Section */}
            <div className="bg-gray-900 py-16">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold mb-8">Customer Reviews</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        {tour.reviews.map((review, index) => (
                            <div key={index} className="bg-black rounded-lg p-6">
                                <div className="flex items-center mb-4">
                                    <div className="flex mr-2">
                                        {[...Array(5)].map((_, i) => (
                                            <FaStar key={i} className={`w-5 h-5 ${i < review.rating ? 'text-yellow-500' : 'text-gray-600'}`} />
                                        ))}
                                    </div>
                                    <span className="font-semibold">{review.user}</span>
                                </div>
                                <p className="text-gray-300">{review.comment}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* FAQ Section */}
            <div className="container mx-auto px-4 py-16">
                <h2 className="text-3xl font-bold mb-8">Frequently Asked Questions</h2>
                <div className="space-y-4">
                    {tour.faqs.map((faq, index) => (
                        <div key={index} className="bg-gray-900 rounded-lg p-6">
                            <h3 className="text-xl font-semibold mb-2">{faq.question}</h3>
                            <p className="text-gray-300">{faq.answer}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TourDetails;
