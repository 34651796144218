import React from 'react';
import { useParams } from 'react-router-dom';
import { MdLocationOn, MdCalendarToday, MdTerrain, MdAttachMoney } from 'react-icons/md';
import { FaMountain, FaRupeeSign } from 'react-icons/fa';
import { trekData } from '../home/trekData';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const TrekDetails = () => {
    const { id } = useParams();
    const trek = trekData.find(t => t.name === id);

    if (!trek) {
        return <div className="text-center py-20">Trek not found</div>;
    }

    return (
        <div className="min-h-screen text-white z-10">
            {/* Hero Section */}
            <div className="relative h-[80vh] w-full">
                <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={false}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="w-full h-full"
                >
                    {trek.images.map((image, index) => (
                        <SwiperSlide key={index}>
                            <img
                                src={image}
                                alt={`${trek.name} - Trek ${index + 1}`}
                                className="w-full h-full object-cover -z-10"
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className='-z-10'>
                    <div className="absolute inset-0 bg-black/50 flex items-center justify-center z-10">
                        <div className="text-center">
                            <h1 className="text-4xl md:text-6xl font-bold mb-4 text-primary headerText ">{trek.name}</h1>
                        </div>
                    </div>
                </div>
            </div>

            {/* Quick Info Section */}
            <div className="max-w-7xl mx-auto px-4 py-8 -mt-20 relative z-10">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                    <InfoCard icon={<MdCalendarToday />} title="Duration" value={trek.trekStats.duration} />
                    <InfoCard icon={<FaMountain />} title="Max Elevation" value={trek.trekStats.maxElevation || 'Not specified'} />
                    <InfoCard icon={<MdTerrain />} title="Difficulty" value={trek.trekStats.difficulty} />
                    <InfoCard icon={<FaRupeeSign />} title="Price" value={trek.trekStats.price} />
                </div>
            </div>

            {/* Overview Section */}
            <div className="max-w-4xl mx-auto px-4 py-16 text-gray-800">
                <h2 className="text-3xl font-bold mb-8 text-center text-primary headerText">Overview</h2>
                <p className="text-lg leading-relaxed">{trek.overview}</p>
            </div>

            {/* Itinerary Section */}
            <div className="max-w-4xl mx-auto px-4 py-16">
                <h2 className="text-3xl font-bold mb-8 text-center text-primary headerText">Itinerary</h2>
                <div className="space-y-6">
                    {trek.itinerary.map((day, index) => (
                        <div key={index} className="flex items-start">
                            <div className="flex-shrink-0 w-12 h-12 rounded-full bg-primary flex items-center justify-center text-white font-bold">
                                {index + 1}
                            </div>
                            <div className="ml-4">
                                <p className="text-lg  text-gray-900">{day}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Requirements Section */}
            <div className="bg-gray-100 py-16">
                <div className="max-w-4xl mx-auto px-4">
                    <h2 className="text-3xl font-bold mb-8 text-center text-primary headerText">Trek Requirements</h2>
                    <div className="grid md:grid-cols-2 gap-8">
                        <div>
                            <h3 className="text-xl font-semibold mb-4 text-gray-800">Fitness Level</h3>
                            <p className="text-gray-700">{trek.requirements.fitnessLevel}</p>
                        </div>
                        <div>
                            <h3 className="text-xl font-semibold mb-4 text-gray-800">Equipment</h3>
                            <p className="text-gray-700">{trek.requirements.equipment}</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Contact Section */}
            <div className="bg-gray-900 text-white py-16">
                <div className="max-w-7xl mx-auto px-4 text-center">
                    <h2 className="text-3xl font-bold mb-4 text-primary headerText">Ready for the Adventure?</h2>
                    <p className="mb-8">Contact us to book your trek or learn more</p>
                    <a
                        href="mailto:spitiexplore@gmail.com"
                        className="inline-block bg-primary text-white px-8 py-3 rounded-lg hover:bg-primary-dark transition-colors"
                    >
                        Contact Now
                    </a>
                </div>
            </div>
        </div>
    );
};

const InfoCard = ({ icon, title, value }) => (
    <div className="bg-yellow-100 backdrop-blur-sm p-6 rounded-lg">
        <div className="flex items-center gap-3">
            <div className="text-2xl text-secondary">{icon}</div>
            <div>
                <h3 className="font-semibold text-primary">{title}</h3>
                <p className='text-black'>{value}</p>
            </div>
        </div>
    </div>
);

export default TrekDetails;

