import React from 'react';
import { MdArrowOutward, MdCalendarToday, MdTerrain, MdAttachMoney } from 'react-icons/md';
import { Link } from 'react-router-dom';

const TrekkingCard = ({ trek }) => {
    const {
        name,
        images,
        description,
        duration,
        difficulty,
        costPerPerson,
        bestTimeToVisit
    } = trek;

    // Format duration (e.g., "3 days 2 nights")
    const formattedDuration = duration
        ? `${duration.days} days ${duration.nights} nights`
        : 'N/A';

    // Format the best time to visit (could be multiple months, so taking the first one)
    const bestSeason = bestTimeToVisit && bestTimeToVisit[0];

    return (
        <div className="sm:w-80 w-full h-auto overflow-hidden rounded-lg shadow-lg bg-white">
            <div className="relative w-full sm:h-52 h-40">
                <Link to={`/trek/${name}`}>
                    <img
                        src={images && images[0] ? images[0] : '/placeholder.svg'}
                        alt={name}
                        className="w-full h-full object-cover"
                    />
                </Link>
                <div className="absolute bottom-2 right-2 sm:w-12 sm:h-12 w-10 h-10 flex items-center justify-center">
                    <Link
                        to={`/trek/${name}`}
                        className="bg-secondary rounded-full w-full h-full flex items-center justify-center transform hover:scale-110 transition-transform duration-300"
                    >
                        <MdArrowOutward className="text-white text-sm sm:text-lg" />
                    </Link>
                </div>
            </div>

            <div className="p-4">
                <h3 className="text-lg font-bold text-gray-800 mb-2">{name}</h3>
                <p className="text-sm text-gray-600 mb-4 line-clamp-2">{description || 'No description available'}</p>
                <div className="flex flex-wrap gap-2 mb-4">
                    <div className="flex items-center text-sm text-gray-700">
                        <MdCalendarToday className="mr-1" />
                        <span>{formattedDuration}</span>
                    </div>
                    <div className="flex items-center text-sm text-gray-700">
                        <MdTerrain className="mr-1" />
                        <span>{difficulty || 'Not specified'}</span>
                    </div>
                    <div className="flex items-center text-sm text-gray-700">
                        {costPerPerson && (
                            <div className="flex items-center text-sm text-gray-700">
                                <MdAttachMoney className="mr-1" />
                                <span>{`₹${costPerPerson}`}</span>
                            </div>
                        )}
                    </div>
                </div>
                {bestSeason && (
                    <div className="text-sm font-semibold text-primary">
                        Best Season: {bestSeason}
                    </div>
                )}
            </div>
        </div>
    );
};

export default TrekkingCard;
