import React, { useState, useEffect, useRef } from 'react';

// Content arrays
const statsData = [
    { label: '100+', description: 'Successful Tours' },
    { label: '50+', description: 'Treks Organized' },
    { label: '200+', description: 'Happy Customers' },
    { label: '10+', description: 'Years of Experience' }
];

const servicesLinks = [
    { href: '#tours', text: 'Tours' },
    { href: '#treks', text: 'Treks' },
    { href: '#stay', text: 'Stay Booking' },
    { href: '#safaris', text: 'Snow Leopard Safari' },
    { href: '#carhire', text: 'Car Hire' }
];

const aboutLinks = [
    { href: '#story', text: 'Our Story' },
    { href: '#company', text: 'Company' },
    { href: '#privacy', text: 'Privacy' },
    { href: '#blog', text: 'Blog' }
];

const contactLinks = [
    { href: '#advertising', text: 'Advertising' },
    { href: '#press', text: 'Press' },
    { href: '#email', text: 'Email' },
    { href: '#partners', text: 'Partners' },
    { href: '#jobs', text: 'Jobs' }
];

const Footer = () => {
    const [openDropdown, setOpenDropdown] = useState(null);
    const dropdownRefs = useRef({});

    // Handle click outside to close dropdown
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRefs.current[openDropdown] && !dropdownRefs.current[openDropdown].contains(event.target)) {
                setOpenDropdown(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [openDropdown]);

    const toggleDropdown = (id) => {
        setOpenDropdown(openDropdown === id ? null : id);
    };

    return (
        <footer className="bg-white">
            {/* Stats Section */}
            <section className="px-8 py-6 mx-auto max-w-7xl">
                <div className="text-center">
                    <div className="grid grid-cols-4 gap-10 md:grid-cols-4 lg:grid-cols-4 lg:gap-12">
                        {statsData.map((stat, index) => (
                            <div key={index} className="col-span-1">
                                <p className="md:text-2xl text-base font-bold text-orange-500">{stat.label}</p>
                                <p className="md:text-xs text-[10px] text-gray-500">{stat.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Subscribe Form */}
            <section className="px-8 py-12 mx-auto max-w-7xl bg-orange-100">
                <div className="text-center">
                    <p className="mb-3 text-xs font-semibold tracking-wider text-orange-400 uppercase">SUBSCRIBE TO OUR NEWSLETTER</p>
                    <form action="#" className="flex flex-col md:flex-row justify-center">
                        <div className="relative flex items-center border border-gray-200 rounded-lg overflow-hidden">
                            <input
                                className="w-full px-3 py-2 text-base leading-normal transition duration-150 ease-in-out bg-white appearance-none focus:outline-none"
                                type="email"
                                placeholder="Enter your email"
                            />
                            <button
                                className="px-4 py-2 text-sm font-medium text-white bg-orange-500 border-2 border-orange-500"
                                type="submit"
                            >
                                Subscribe
                            </button>
                        </div>
                    </form>
                    <p className="text-xs leading-normal text-gray-500 mt-2">Get the latest updates and news about our services and offers.</p>
                </div>
            </section>

            {/* Footer Section */}
            <section className="px-8 py-4 mx-auto max-w-7xl m-10">
                <div className="flex flex-col md:flex-row md:items-start md:justify-between">
                    <div className="mb-1 md:mb-0 text-center">
                        <a href="#_" className="text-xl font-black leading-none text-orange-500 select-none logo headerText">Explore Spiti</a>
                        <p className="my-4 text-xs leading-normal text-gray-500">
                            Explore the beauty of Spiti Valley with our comprehensive travel services, including tours, treks, stay bookings, and more.
                        </p>
                    </div>
                    <div className="hidden md:flex flex-col md:flex-row md:space-x-10">
                        {/* Services Links */}
                        <div className="relative group">
                            <p className="mb-3 text-xs font-semibold tracking-wider text-gray-400 uppercase">Services</p>
                            <div className="hidden group-hover:block absolute bg-white border border-gray-200 rounded-lg shadow-lg">
                                {servicesLinks.map((link, index) => (
                                    <a key={index} href={link.href} className="block px-4 py-2 text-xs font-medium text-nowrap text-gray-500 hover:text-orange-700">
                                        {link.text}
                                    </a>
                                ))}
                            </div>
                        </div>

                        {/* About Links */}
                        <div className="relative group">
                            <p className="mb-3 text-xs font-semibold tracking-wider text-gray-400 uppercase">About</p>
                            <div className="hidden group-hover:block absolute bg-white border border-gray-200 rounded-lg shadow-lg">
                                {aboutLinks.map((link, index) => (
                                    <a key={index} href={link.href} className="block px-4 py-2 text-xs font-medium text-nowrap text-gray-500 hover:text-orange-700">
                                        {link.text}
                                    </a>
                                ))}
                            </div>
                        </div>

                        {/* Contact Links */}
                        <div className="relative group">
                            <p className="mb-3 text-xs font-semibold tracking-wider text-gray-400 uppercase">Contact</p>
                            <div className="hidden group-hover:block absolute bg-white border border-gray-200 rounded-lg shadow-lg">
                                {contactLinks.map((link, index) => (
                                    <a key={index} href={link.href} className="block px-4 py-2 text-xs font-medium text-nowrap text-gray-500 hover:text-orange-700">
                                        {link.text}
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* Mobile Dropdowns */}
                    <div className="md:hidden flex flex-row space-x-4 justify-center">
                        {/* Services Dropdown */}
                        <div className="relative mb-4" ref={(el) => (dropdownRefs.current.services = el)}>
                            <button
                                onClick={() => toggleDropdown('services')}
                                className="text-orange-500 transition hover:text-orange-700 focus:outline-none"
                            >
                                Services
                            </button>
                            {openDropdown === 'services' && (
                                <div className="absolute top-0 left-0 transform -translate-y-full w-40 bg-white shadow-lg rounded-lg border border-gray-200">
                                    {servicesLinks.map((link, index) => (
                                        <a key={index} href={link.href} className="block px-4 py-2 text-xs font-medium text-gray-500 hover:text-orange-700">
                                            {link.text}
                                        </a>
                                    ))}
                                </div>
                            )}
                        </div>

                        {/* About Dropdown */}
                        <div className="relative mb-4" ref={(el) => (dropdownRefs.current.about = el)}>
                            <button
                                onClick={() => toggleDropdown('about')}
                                className="text-orange-500 transition hover:text-orange-700 focus:outline-none"
                            >
                                About
                            </button>
                            {openDropdown === 'about' && (
                                <div className="absolute top-0 left-0 transform -translate-y-full w-24 bg-white shadow-lg rounded-lg border border-gray-200">
                                    {aboutLinks.map((link, index) => (
                                        <a key={index} href={link.href} className="block px-4 py-2 text-xs font-medium text-gray-500 hover:text-orange-700">
                                            {link.text}
                                        </a>
                                    ))}
                                </div>
                            )}
                        </div>

                        {/* Contact Dropdown */}
                        <div className="relative" ref={(el) => (dropdownRefs.current.contact = el)}>
                            <button
                                onClick={() => toggleDropdown('contact')}
                                className="text-orange-500 transition hover:text-orange-700 focus:outline-none"
                            >
                                Contact
                            </button>
                            {openDropdown === 'contact' && (
                                <div className="absolute top-0 left-0 transform -translate-y-full bg-white shadow-lg rounded-lg border border-gray-200">
                                    {contactLinks.map((link, index) => (
                                        <a key={index} href={link.href} className="block px-4 py-2 text-xs font-medium text-gray-500 hover:text-orange-700">
                                            {link.text}
                                        </a>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </footer>
    );
};

export default Footer;
