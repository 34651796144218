import React from 'react';
import { useParams } from 'react-router-dom';
import { FaCalendar, FaClock, FaUsers, FaMapMarkerAlt, FaStar, FaRupeeSign } from 'react-icons/fa';
import toursData from './toursData';

const TestDetails = () => {
    const { id } = useParams();
    const tour = toursData.find(t => t.id === parseInt(id));

    if (!tour) return <div className="text-center py-10 text-white">Tour not found</div>;

    return (
        <div className="min-h-screen bg-gray-100 text-gray-800">
            {/* Hero Section */}
            <div className="relative h-[80vh]">
                <img src={tour.images[0]} alt={tour.title} className="w-full h-full object-cover" />
                <div className="absolute inset-0 bg-black bg-opacity-50 flex items-end">
                    <div className="container mx-auto px-4 py-8">
                        <h1 className="text-xl md:text-4xl font-bold text-white mb-4 text-primary headerText">{tour.title}</h1>
                        <p className="text-sm md:text-xl text-white mb-8">{tour.description}</p>
                    </div>
                </div>
            </div>

            {/* Tour Details */}
            <div className="container mx-auto px-4 py-16">
                <div className="flex flex-wrap -mx-4">
                    <div className="w-full lg:w-2/3 px-4 mb-8 lg:mb-0">
                        <div className="bg-white rounded-lg p-6 mb-8 shadow-md">
                            <h2 className="text-2xl font-bold mb-4 text-primary headerText">Tour Highlights</h2>
                            <ul className="list-disc list-inside">
                                {tour.highlights.map((highlight, index) => (
                                    <li key={index} className="mb-2">{highlight}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="bg-white rounded-lg p-6 shadow-md">
                            <h2 className="text-2xl font-bold mb-4 text-primary headerText">Itinerary</h2>
                            {tour.itinerary.map((day, index) => (
                                <div key={index} className="mb-6 last:mb-0">
                                    <h3 className="text-xl font-semibold mb-2">Day {day.day}: {day.title}</h3>
                                    <p className="mb-2">{day.description}</p>
                                    <ul className="list-disc list-inside">
                                        {day.activities.map((activity, actIndex) => (
                                            <li key={actIndex}>{activity}</li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="w-full lg:w-1/3 px-4">
                        <div className="bg-white rounded-lg p-6 sticky top-4 shadow-md">
                            <h2 className="text-2xl font-bold mb-4 text-primary headerText">Tour Details</h2>
                            <div className="space-y-4">
                                <div className="flex items-center">
                                    <FaCalendar className="mr-2 text-primary" />
                                    <span>Start Date: {new Date(tour.startDate).toLocaleDateString()}</span>
                                </div>
                                <div className="flex items-center">
                                    <FaClock className="mr-2 text-primary" />
                                    <span>Duration: {tour.duration}</span>
                                </div>
                                <div className="flex items-center">
                                    <FaUsers className="mr-2 text-primary" />
                                    <span>Group Size: {tour.groupSize} people</span>
                                </div>
                                <div className="flex items-center">
                                    <FaMapMarkerAlt className="mr-2 text-primary" />
                                    <span>Difficulty: {tour.difficultyLevel}</span>
                                </div>
                                <div className="text-2xl font-bold flex items-center">
                                    <FaRupeeSign className="mr-2 text-primary" />
                                    <span>{tour.price.discounted.toLocaleString()}</span>
                                    {tour.price.regular !== tour.price.discounted && (
                                        <span className="text-lg line-through text-gray-500 ml-2">₹{tour.price.regular.toLocaleString()}</span>
                                    )}
                                </div>
                                <button className="w-full bg-primary text-white font-bold py-2 px-4 rounded hover:bg-primary-dark transition duration-300">
                                    Book Now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Reviews Section */}
            <div className="bg-white py-16">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold mb-8 text-primary headerText">Customer Reviews</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        {tour.reviews.map((review, index) => (
                            <div key={index} className="bg-gray-100 rounded-lg p-6">
                                <div className="flex items-center mb-4">
                                    <div className="flex mr-2">
                                        {[...Array(5)].map((_, i) => (
                                            <FaStar key={i} className={`w-5 h-5 ${i < review.rating ? 'text-yellow-500' : 'text-gray-300'}`} />
                                        ))}
                                    </div>
                                    <span className="font-semibold">{review.user}</span>
                                </div>
                                <p className="text-gray-600">{review.comment}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* FAQ Section */}
            <div className="container mx-auto px-4 py-16">
                <h2 className="text-3xl font-bold mb-8 text-primary headerText">Frequently Asked Questions</h2>
                <div className="space-y-4">
                    {tour.faqs.map((faq, index) => (
                        <div key={index} className="bg-white rounded-lg p-6 shadow-md">
                            <h3 className="text-xl font-semibold mb-2">{faq.question}</h3>
                            <p className="text-gray-600">{faq.answer}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TestDetails;