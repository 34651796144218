import TestCard from './testCard';
import toursData from './toursData';

const TestSlider = () => {
    return (

        <section className="py-16" >
            <div className="container mx-auto px-4">
                <h2 className="text-3xl font-bold text-center mb-8 headerText text-primary">Featured Tours</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
                    {toursData.map(tour => (
                        <TestCard key={tour.id} tour={tour} />
                    ))}
                </div>
            </div>
        </section >
    );
};

export default TestSlider;