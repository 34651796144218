import TREK1 from "../../assets/images/trek1.jpg"
import TREK2 from "../../assets/images/trek2.jpg"
import TREK3 from "../../assets/images/trek3.jpg"

export const trekData = [
    {
        name: "Parang La Trek",
        images: [
            TREK1,
            TREK2,
            TREK3,
        ],
        itinerary: [
            "Day 1: Drive from Kaza to Kibber",
            "Day 2: Trek from Kibber to Dumla",
            "Day 3: Dumla to Thaltak Meadow",
            "Day 4: Thaltak to Parang La Base Camp",
            "Day 5: Cross Parang La Pass and descend to Pare Chu River",
            "Day 6: Trek along Pare Chu River",
            "Day 7: Reach Tso Moriri Lake",
            "Day 8: Explore Karzok village and depart",
        ],
        overview: "Parang La trek is an ancient trade route between Spiti, Tibet & Changthang in Ladakh region. The trail offers diverse landscapes, cultural encounters, and stunning views of Tso Moriri Lake.",
        trekStats: {
            duration: "10 Nights 11 days",
            difficulty: "Challenging/Moderate",
            bestSeason: "Mid June To August",
            price: "On request",
            maxElevation: "5,590 (mts)",
        },
        requirements: {
            fitnessLevel: "High endurance and prior high-altitude trekking experience required.",
            equipment: "Warm clothing, sturdy trekking boots, sleeping bag, and high-altitude gear.",
        },
    },
    {
        name: "Pin-Parvati Trek",
        images: [
            TREK1,
            TREK2,
            TREK3,],
        itinerary: [
            "Day 1: Arrive in Barshaini",
            "Day 2: Trek to Kheerganga",
            "Day 3: Kheerganga to Tunda Bhuj",
            "Day 4: Tunda Bhuj to Mantalai Lake",
            "Day 5-6: Mantalai to Pin Parvati Pass",
            "Day 7: Descend to Pin Valley",
            "Day 8: Trek to Mud Village",
        ],
        overview: "The Pin-Parvati Trek is one of the most challenging and breathtaking high-altitude treks in India, linking the lush Parvati Valley to the arid Pin Valley in Spiti.",
        trekStats: {
            duration: "12 Nights 13 days",
            difficulty: "Challenging",
            bestSeason: "Mid June To August",
            price: "On request",
            maxElevation: "5,319 (mts)",
        },
        requirements: {
            fitnessLevel: "Excellent physical fitness and prior experience in high-altitude trekking.",
            equipment: "High-quality trekking gear, warm layers, and glacier equipment.",
        },
    },
    {
        name: "Pin-Bhabha Trek",
        images: [
            TREK1,
            TREK2,
            TREK3,  ],
        itinerary: [
            "Day 1: Drive from Shimla to Kafnu",
            "Day 2: Trek from Kafnu to Mulling",
            "Day 3: Mulling to Kara",
            "Day 4: Kara to Phustirang",
            "Day 5: Cross Pin-Bhabha Pass to Mud Village",
        ],
        overview: "The Pin-Bhabha Trek connects the lush Bhabha Valley in Kinnaur to the stark, arid landscapes of the Pin Valley in Spiti, offering dramatic transitions in terrain.",
        trekStats: {
            duration: "7 Nights 8 days",
            difficulty: "Moderate to Challenging",
            bestSeason: "Mid June To August",
            price: "On request",
            maxElevation: "4,850 (mts)",
        },
        requirements: {
            fitnessLevel: "Good physical fitness and some prior trekking experience.",
            equipment: "Trekking gear suitable for both forest and high-altitude terrain.",
        },
    },
    {
        name: "Fluted Peak Trek",
        images: [
            TREK1,
            TREK2,
            TREK3,  ],
        itinerary: [
            "Day 1-2: Acclimatization in Kaza",
            "Day 3-4: Trek to Fluted Peak Base Camp",
            "Day 5-6: Climb Fluted Peak (6139m)",
            "Day 7-8: Trek to Larimo Peak Base Camp",
            "Day 9-10: Climb Larimo Peak (5995m)",
            "Day 11-12: Return trek and drive to Manali",
        ],
        overview: "The Fluted Peak Trek is an offbeat and adventurous expedition in the Losar valley of Spiti, featuring climbs of Fluted Peak (6139m) and Larimo Peak (5995m).",
        trekStats: {
            duration: "12 Nights 13 days",
            difficulty: "Challenging",
            bestSeason: "Mid June To August",
            price: "On request",
            maxElevation: "5,200 (mts)",
        },
        requirements: {
            fitnessLevel: "Excellent physical condition and prior experience in high-altitude climbing.",
            equipment: "Professional mountaineering gear, including ice axes and crampons.",
        },
    },
    {
        name: "Kanamo Peak Trek",
        images: [
            TREK1,
            TREK2,
            TREK3, ],
        itinerary: [
            "Day 1: Arrive in Kaza, acclimatize",
            "Day 2: Drive to Kibber, short acclimatization walk",
            "Day 3: Trek to Kanamo Base Camp",
            "Day 4: Summit Kanamo Peak, return to Base Camp",
            "Day 5: Trek back to Kibber and drive to Kaza",
        ],
        overview: "Kanamo Peak Trek offers a challenging climb to 5,970 meters, providing breathtaking views of the Spiti Valley and surrounding Himalayan ranges.",
        trekStats: {
            duration: "12 Nights 13 days",
            difficulty: "Moderate/Challenging",
            bestSeason: "Mid June To August",
            price: "On request",
            maxElevation: "5,970 (mts)",
        },
        requirements: {
            fitnessLevel: "Good physical fitness and some high-altitude trekking experience.",
            equipment: "Warm clothing, trekking boots, and basic mountaineering gear.",
        },
    },
    {
        name: "Hampta Pass and Moon Lake Trek",
        images: [
            TREK1,
            TREK2,
            TREK3, ],
        itinerary: [
            "Day 1: Drive from Manali to Jobra, trek to Chika",
            "Day 2: Trek from Chika to Balu Ka Ghera",
            "Day 3: Cross Hampta Pass, trek to Shea Goru",
            "Day 4: Trek to Chatru, drive to Chandratal (Moon Lake)",
            "Day 5: Explore Chandratal, drive back to Manali",
        ],
        overview: "The Hampta Pass and Moon Lake Trek offers diverse landscapes, from lush Kullu Valley to the barren beauty of Spiti, culminating at the stunning Chandratal Lake.",
        trekStats: {
            duration: "4 Nights, 5 days",
            difficulty: "Moderate",
            bestSeason: "June to October",
            price: "On request",
            maxElevation: "4,270 (mts)",
        },
        requirements: {
            fitnessLevel: "Moderate fitness level, suitable for beginners with basic trekking experience.",
            equipment: "Standard trekking gear, warm clothing, and good quality hiking boots.",
        },
    },
    {
        name: "Deo Tibba Trek",
        images: [
            TREK1,
            TREK2,
            TREK3, ],
        itinerary: [
            "Day 1: Drive from Manali to Khanol, trek to Chikka",
            "Day 2: Trek from Chikka to Seri",
            "Day 3: Seri to Tenta (Deo Tibba Base Camp)",
            "Day 4: Explore around Deo Tibba Base Camp",
            "Day 5: Trek back to Chikka and drive to Manali",
        ],
        overview: "The Deo Tibba Trek offers stunning views of the 6,001m Deo Tibba peak and surrounding glaciers, passing through beautiful forests and meadows in the Pir Panjal Range.",
        trekStats: {
            duration: "4 Nights, 5 days",
            difficulty: "Moderate to Challenging",
            bestSeason: "June to October",
            price: "On request",
            maxElevation: "4,270 (mts)",
        },
        requirements: {
            fitnessLevel: "Good physical fitness and some prior trekking experience recommended.",
            equipment: "Standard trekking gear, warm layers, and comfortable hiking boots.",
        },
    },
];

export default trekData;