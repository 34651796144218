import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay';
import { MdLocationOn } from 'react-icons/md';
import { Link } from 'react-router-dom'; // Import Link
import { PopularData } from '../popular/PopularData';

const PopularDestinations = () => {
    return (
        <div className="container mx-auto px-4 py-4">
            {/* Header */}
            <h1 className="sm:text-2xl text-primary text-xl font-bold text-center my-8 headerText">Popular Destinations</h1>

            {/* Grid Layout for larger screens */}
            <div className="hidden lg:grid grid-cols-5 gap-4 grid-flow-row auto-rows-[minmax(100px,0fr)]">
                {PopularData.map((destination, index) => (
                    <Link
                        key={index}
                        to={`/destination/${destination.id}`} // Link to destination page
                        className={`relative overflow-hidden bg-gray-200 rounded-lg ${index === 0 ? 'col-span-2 row-span-2' : ''}`}
                    >
                        <img
                            src={destination.image}
                            alt={destination.name}
                            className="object-cover h-full w-full"
                        />
                        <div className="bg-black bg-opacity-50 p-2 text-white text-center sm:text-xs text-[10px] absolute bottom-0 w-full flex items-center justify-center">
                            <MdLocationOn className="mr-1" />
                            {destination.name}
                        </div>
                    </Link>
                ))}
            </div>

            {/* Swiper Carousel for smaller screens */}
            <div className="lg:hidden">
                <Swiper
                    spaceBetween={16}
                    slidesPerView={2}
                    loop={true}
                    modules={[Autoplay]}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    breakpoints={{
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 24,
                        },
                    }}
                >
                    {PopularData.map((destination, index) => (
                        <SwiperSlide key={index} className="relative h-64">
                            <Link to={`/destination/${destination.id}`}> {/* Link to destination page */}
                                <div className="overflow-hidden bg-gray-200 rounded-lg h-full">
                                    <img
                                        src={destination.image}
                                        alt={destination.name}
                                        className="object-cover h-full w-full"
                                    />
                                    <div className="bg-black bg-opacity-50 p-2 text-white text-center absolute bottom-0 w-full flex items-center justify-center">
                                        <MdLocationOn className="mr-1" />
                                        {destination.name}
                                    </div>
                                </div>
                            </Link>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
};

export default PopularDestinations;
